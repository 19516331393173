import React, {ChangeEvent, useRef} from 'react';
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {setModalSettings} from "../../../store/slices/modalSettings";

interface ToggleProps {
  typeName?: string,
}

const Toggle = ({typeName}:ToggleProps) => {
  const {modalSettings} = useAppSelector(state => state.modalSettings);
  const refInput = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const changeHandler = (event:ChangeEvent) => {
    const target = event.target;
    const node = target.parentElement?.querySelector('.type-setting-modal');
    const nodeType = node?.id;

    if(refInput.current != undefined) {
      const obj = {
        // @ts-ignore
        [nodeType]: {
          name: nodeType,
          status: refInput.current.checked,
        }
      }
      dispatch(setModalSettings(obj));
    }
  }

  let defaultCheck = false
  if(typeName && modalSettings !== null){
    if(modalSettings[typeName].status === true) defaultCheck = true;
  }

  return (
    <input ref={refInput} checked={defaultCheck ? true : false} onChange={(e) => changeHandler(e)}
      className="
      ``flex
        flex-row
        items-center
        h-4.5
        w-10
        appearance-none
        rounded-[1rem]
        bg-black/25
        before:pointer-events-none
        before:absolute
        before:h-3.5
        before:w-3.5
        before:rounded-full
        before:bg-transparent
        before:content-['']
        after:absolute
        after:mt-[1px]
        after:z-[2]
        after:h-[22px]
        after:w-[22px]
        after:rounded-full
        after:border-none
        after:bg-white
        after:shadow-switch-2
        after:transition-[background-color_0.2s,transform_0.2s]
        after:content-['']
        checked:bg-black
        checked:after:absolute
        checked:after:z-[2]
        checked:after:ms-[1.0625rem]
        checked:after:h-[22px]
        checked:after:w-[22px]
        checked:after:rounded-full
        checked:after:border-none
        checked:after:bg-primary
        checked:after:shadow-switch-1
        checked:after:transition-[background-color_0.2s,transform_0.2s]
        checked:after:content-['']
        hover:cursor-pointer
        focus:outline-none
        focus:before:scale-100
        focus:before:opacity-[0.12]
        focus:before:shadow-switch-3
        focus:before:shadow-black/60
        focus:before:transition-[box-shadow_0.2s,transform_0.2s]
        focus:after:absolute focus:after:z-[1]
        focus:after:block
        focus:after:h-[22px]
        focus:after:w-[22px]
        focus:after:rounded-full
        focus:after:content-['']
        checked:focus:border-primary
        checked:focus:bg-primary
        checked:focus:before:ms-[1.0625rem]
        checked:focus:before:scale-100
        checked:focus:before:shadow-switch-3
        checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]
        dark:bg-white/25
        dark:after:bg-surface-dark
        dark:checked:bg-primary
        dark:checked:after:bg-primary"
      type="checkbox"
      role="switch"
      id="flexSwitchCheckDefault01" />
  );
};

export default Toggle;