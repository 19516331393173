import React from 'react';
import FixButton from '../main/fixButton';
import {formatInternationalPhoneNumber} from "../helpers";

const Footer = ({agent}: any) => {
    const phone = agent.phone !== null ? formatInternationalPhoneNumber(agent.phone, 'RU') : null;
    return (
        <footer className={'w-[1280px] max-md:max-w-[1280px] max-md:w-full max-md:mx-auto'}>
            <FixButton/>
            <div className={'max-w-[1280px] w-full mx-auto py-[50px] px-[14px] flex flex-row justify-end max-md:justify-start'}>
                <div className={'max-md:w-full flex flex-row max-md:flex-col max-w-[690px] gap-[20px] items-center justify-end max-md:justify-start'}>
                    <div className={'relative flex flex-col leading-[1.75] items-start max-md:w-full'}>
                        <p className="name uppercase font-light text-[14px] max-md:font-bold max-md:text-[#4D4D4D]">
                            {agent.first_name ? agent.first_name : null} {agent.last_name ? agent.last_name : null}
                        </p>
                        <a href={agent.phone ? `tel:${agent.phone}`: '#'} className="number text-[24px] max-md:font-semibold max-md:text-[20px]">
                            {phone ? phone : null}
                        </a>
                        <a href={agent.email ? `mailto:${agent.email}` : '#'} className="mail underline max-md:font-semibold max-md:text-[16px]">
                            {agent.email}
                        </a>
                        {
                            agent.image ?
                                <img
                                    className={'agent-footer absolute right-0 bottom-[12px] hidden w-[62px] h-[62px] rounded-[8px] max-md:block'}
                                    src={agent.image.links.preview} alt="agent-photo"/> :
                                null
                        }
                    </div>
                    <div className={'max-md:w-full justify-start'}>
                        <ul className={'flex flex-col gap-[6px]'}>
                            <li className={'flex flex-row items-center gap-[12px]'}>
                                <img src='icons/telegram.svg' alt="" className={'w-[16px] h-[16px]'}/>
                                <p className={'max-md:font-bold max-md:text-[14px] text-[#151515]'}>Telegram</p>
                                <a className={'underline underline-offset-2 before:border-none after:border-none max-md:font-normal text-[##151515] max-md:text-[14px]'} href={agent.phone ? `https://t.me/${agent.phone}` : '#'}>
                                    {phone}
                                </a>
                            </li>
                            <li className={'flex flex-row items-center gap-[12px]'}>
                                <img src='icons/whatsapp.svg' alt="wa-icon" className={'w-[16px] h-[16px]'}/>
                                <p className={'max-md:font-bold max-md:text-[14px] text-[#151515]'}>WhatsApp</p>
                                <a className={'underline underline-offset-2 before:border-none after:border-none max-md:font-normal text-[##151515] max-md:text-[14px]'}
                                   href={agent.phone ? `https://wa.me/${agent.phone}` : '#'}>
                                    {phone}
                                </a>
                            </li>
                            <li className={'flex flex-row items-center gap-[12px]'}>
                                <img src='icons/viber.svg' alt="viber-icon" className={'w-[16px] h-[16px]'}/>
                                <p className={'max-md:font-bold max-md:text-[14px] text-[#151515]'}>Viber</p>
                                <a className={'underline underline-offset-2 before:border-none after:border-none max-md:font-normal text-[##151515] max-md:text-[14px]'}
                                   href={agent.phone ? `https://viber.me/${agent.phone}` : '#'}>
                                    {phone}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;