import React, {Suspense} from 'react';
import { request } from '../map/mapData';
import './../../styles/map.css'
import { useInView } from 'react-intersection-observer';
import {useAppSelector} from "../../hooks";

const MapY = React.lazy(() => import('../map/map'));

const SectionInfrostrukture = ({ data, estate }: any) => {

    const {skipStatus} = useAppSelector(state => state.skipStatusSlice);

    const hcDesc = {
        name: data.developer ? data.developer.name : data.name || 'нет названия',
        city: data.city ? data.city.name : null,
        address: data.address ? data.address : null
    }

    let [ref, inView] = useInView({
        triggerOnce: true, // Компонент загружается только один раз
        threshold: 0.1, // Загрузка начинается, когда 10% компонента видны
        skip: skipStatus ? true : false, // меняем на true при клике на иконку поделиться. Observer прекратит работу.
    });

    return (
        <section className={'flex flex-col'}>
            <h2 className={'mb-[30px] leading-[44px] font-[600] text-[36px] max-md:text-[22px] max-md:mb-[10px] text-[#151515]'}>Инфраструктура</h2>
            <div className={'flex flex-col gap-[8px] mb-[12px]'}>
                <div className={'max-md:leading-[20px]'}>
                    {data.developer ? data.developer.name + ', ' : null} {data.address ? data.address : null}
                </div>
                <div className={'flex flex-wrap gap-[32px] max-md:gap-[4px] max-md:flex-col'}>
                    {data.hc_metros?.map((hc_metro: any, _idx: number) => (
                        <div key={hc_metro.id} className={data.hc_metros.length > 1 ? 'infrastructers-border flex flex-row gap-[8px] items-center max-md:border-none border-r max-md:border-[#E6E6E6] pr-[7px]' :
                        'flex flex-row gap-[8px] items-center'}>
                            {
                                hc_metro.metro.color ?
                                    <span style={{background: hc_metro.metro.color}} className={`block w-[10px] h-[10px] rounded-[50%]`}/> : null
                            }
                            <span className={'text-[16px] text-[#4D4D4D] leading-[24px] font-[600]'}>{hc_metro.metro.name}</span>
                            <span className={'flex flex-row gap-[4px] items-center text-[14px] text-[#A6A6A6] font-[400] leading-[18px]'}>
                                <img src={`icons/metros/${hc_metro.metro_infos[_idx]?.metro_way_alias}.svg`} alt=""/>
                                {
                                    hc_metro.metro_infos[0] ? <span>{hc_metro.metro_infos[0].minutes} мин.</span> : null
                                }
                            </span>
                        </div>
                    ))}
                    {
                        data.infrastructures.length && data.hc_metros?.length ? <hr className={'border-1 border-[#E6E6E6]'}/> : null
                    }
                </div>
                <div className={'flex flex-col gap-[14px] max-md:gap-[4px] max-md:flex-col max-md:mt-[0px] mt-[36px]'}>
                    {
                        data.infrastructures?.map((el:any) => (
                            <ul key={el.id} className={'infrastructures-ul max-md:grid-cols-1 gap-[8px]'}>
                                <li className={'flex flex-row items-center'}>
                                    <img src={require(`../../svg/${el.icon}.svg`)} className={'mr-[12px]'}/>
                                    <p className={'font-normal text-[#4D4D4D] whitespace-normal'}>{el.name}</p>
                                </li>
                            </ul>
                        ))
                    }
                </div>
            </div>
            {
                skipStatus && !inView ?
                    <Suspense fallback={<div>Loading...</div>}>
                        <MapY coordinates={{
                            latitude: data.latitude,
                            longitude: data.longitude,
                        }} request={request} hcDesc={hcDesc} fromPDF={false}/>
                    </Suspense>
                    :
                    <div ref={ref}>
                        {inView ? (
                            <Suspense fallback={<div>Loading...</div>}>
                                <MapY coordinates={{
                                    latitude: data.latitude,
                                    longitude: data.longitude,
                                }} request={request} hcDesc={hcDesc} fromPDF={false}/>
                            </Suspense>
                        ) : (
                            <div>Компонент загрузится, когда попадет в видимую область экрана.</div>
                        )}
                    </div>
            }
        </section>
    );
};

export default SectionInfrostrukture;