import React from 'react';

const ErrorUrl = () => {
    return (
        <div
            className={'max-w-[1280px] w-full min-h-[100vh] items-center justify-center mx-auto py-[50px] max-md:pt-[10px] px-[14px] flex flex-col gap-[80px] max-md:gap-[40px]'}>
            <div className={'flex justify-center'}>
                <div className={'flex flex-col md:flex-row gap-[25px] items-center'}>
                    <div>
                        <h1 className={'text-[24px] md:text-[36px]'}>Ошибка, нет данных</h1>
                        <p className={'text-[16px] md:text-[20px]'}>Свяжитесь с вашим менеджером для предоставления
                            презентации</p>
                    </div>
                    <img src="/img/errorUrl.svg" alt=""/>
                </div>
            </div>
        </div>
    );
};

export default ErrorUrl;