import { createSlice } from '@reduxjs/toolkit';
import {RootState} from "../store";

interface DataState {
    data: any;
}
const initialState: DataState = {
    data: {}
}
const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setData(state, action) {
            state.data = action.payload;
        },
    },
});
export const { setData } = dataSlice.actions;
export default dataSlice.reducer;

export const apiData = (state: RootState) => state.dataSlice;

