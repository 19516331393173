import { configureStore } from '@reduxjs/toolkit';
import dataSlice from "./slices/dataSlice";
import dataStatic from "./slices/dataStatic";
import dataPDFSetting from "./slices/dataPDFSettings";
import scrollSlice from "./slices/scrollSlice";
import modalSettings from "./slices/modalSettings";
import skipStatusSlice from  "./slices/observerCheck"
import dataReadyIcons from "./slices/staticReady"
import inViewSlice from "./slices/inViewsSlice"

export const store = configureStore({
    reducer: {
        dataSlice,
        dataStatic,
        dataPDFSetting,
        scrollSlice,
        modalSettings,
        skipStatusSlice,
        dataReadyIcons,
        inViewSlice
    },
});

export type RootState = ReturnType<typeof store.getState>; // A global type to access reducers types
export type AppDispatch = typeof store.dispatch; //