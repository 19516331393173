import { createSlice } from '@reduxjs/toolkit';
import {RootState} from "../store";

interface DataState {
    inViewSlice: boolean;
}
const initialState: DataState = {
    inViewSlice: false
}
const inViewSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setInViewSlice(state, action) {
            state.inViewSlice = action.payload;
        },
    },
});
export const { setInViewSlice } = inViewSlice.actions;
export default inViewSlice.reducer;

export const dataReady = (state: RootState) => state.inViewSlice;
