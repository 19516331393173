import React from 'react';
import {formatPrice, getNoun} from "../helpers";

const SectionObject = ({data}: any) => {

    function getQuarter(dateString: any) {
        const date = new Date(dateString);
        const month = date.getMonth();
        const quarter = Math.floor(month / 3) + 1;
        return quarter;
    }

    const year = data.deadline ? new Date(data.deadline).getFullYear() : 'нет данных';

    return (
        <section>
            <h2 className={'mb-[30px] leading-[44px] font-[600] text-[36px] max-md:text-[22px] max-md:mb-[15px] text-[#151515]'}>
                Об объекте
            </h2>
            <div className={'grid grid-cols-2 max-md:grid-cols-1 gap-4 max-md:gap-[20px]'}>
                <div className={'grid grid-cols-2 grid-rows-5 gap-[20px] max-md:grid-rows-1'}>
                    <div><b>Цены</b></div>
                    <div>{data.price_min ? formatPrice(data.price_min) : '-'} – {data.price_min ? formatPrice(data.price_max) : '-'}</div>
                    <div><b>Цена за м²</b></div>
                    <div>{data.price_m2_min ? formatPrice(data.price_m2_min) : '-'} – {data.price_m2_max ? formatPrice(data.price_m2_max) : '-'}</div>
                    <div><b>Площади квартир</b></div>
                    <div>{data.total_area_min ? data.total_area_min : '-'} м² – {data.total_area_max ? data.total_area_max : '-'} м²</div>
                    {
                        data.deadline ?
                            <>
                                <div><b>Срок выдачи ключей</b></div>
                                <div>{getQuarter(data.deadline)} квартал {year} года</div>
                            </> : null
                    }
                </div>
                <div className={'grid grid-cols-2 grid-rows-5 gap-[20px] max-md:grid-rows-1'}>
                    {
                        data.decorations.length ?
                            <>
                                <div><b>Отделка</b></div>
                                <div>
                                    {data.decorations.length + ' ' + getNoun(data.decorations.length, 'вид', 'вида', 'видов')}
                                </div>
                            </> : null
                    }
                    {
                        data.ceiling_height ?
                            <>
                                <div><b>Потолки</b></div>
                                <div>{data.ceiling_height}</div>
                            </> : null
                    }
                    {
                        data.balcony_area !== null && data.loggia ?
                            <>
                                <div><b>Лоджия</b></div>
                                <div>
                                    {data.loggia ? data.loggia + 'м² (утеплена)' : null}
                                    {data.balcony_area !== null ? `${data?.balcony_area} м<sup>2</sup> (утеплена)` : '-'}
                                </div>
                            </> : null
                    }
                    {
                        data.floor_max ?
                            <>
                                <div><b>Этажность</b></div>
                                {
                                    data.floor_min ?
                                        <div>{data.floor_min} – {data.floor_max}</div>
                                        : <div>{data.floor_max}</div>
                                }
                            </> : null
                    }
                    {
                        data.total_apartments ?
                            <>
                                <div><b>Квартир в продаже</b></div>
                                <div>{data.total_apartments}</div>
                            </> : null
                    }
                    {
                        data.type ?
                            <>
                                <div><b>Тип ЖК</b></div>
                                <div>{data.type}</div>
                            </> : null
                    }
                </div>
            </div>
        </section>
    );
};

export default SectionObject;