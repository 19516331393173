import React, {useEffect, useState} from 'react';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import {useLocation, useSearchParams} from 'react-router-dom';
import Main from './components/main/main';
import InvalidUrl from "./components/404/invalidUrl";
import ErrorUrl from "./components/404/errorUrl";
import YandexMetrik from "./components/yandexMetrik";
import {Helmet} from "react-helmet";
import {setData} from "./store/slices/dataSlice";
import {useAppDispatch} from "./hooks";

function App() {
    const api = process.env.REACT_APP_BACK_URL
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [errorPage, setErrorPage] = useState<any>();
    const [errorTitle, setErrorTitle] = useState<string>()
    const builderHouse = searchParams.get('builder');
    const house = searchParams.get('house');
    const agent = searchParams.get('agent');
    const [data2, setData2] = useState<any>();
    const location = useLocation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const url = builderHouse !== null ? `${api}/builder/${builderHouse}/${agent}` : `${api}/house/${house}/${agent}`;
            const dataUrl = await fetch(url, {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                    'Mode': 'no-cors',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET',
                    'Access-Control-Allow-Headers': '*',
                    'Referrer-Policy': 'strict-origin-when-cross-origin',
                },
            }).then(async data => {
                const res: any = await data.json()
                if (!res.statusCode && !res.message) {
                    if(Boolean(location.search) === false){
                        setErrorPage(<ErrorUrl/>)
                        return undefined
                    }
                    setErrorPage(null)
                    return res
                } else {
                    if(Boolean(location.search) === false){
                        setErrorPage(<ErrorUrl/>)
                        setErrorTitle(res.message)
                        return undefined
                    }
                    if(res.message === 'Указанные данные были неверными.'){
                        setErrorPage(<InvalidUrl/>)
                        setErrorTitle(res.message)
                        return undefined
                    }
                    if(res.message === 'Ресурс не найден.'){
                        setErrorPage(<ErrorUrl/>)
                        setErrorTitle(res.message)
                        return undefined
                    }
                    if (res.statusCode === 404) {
                        setErrorPage(<InvalidUrl/>)
                        setErrorTitle(res.statusCode)
                        return undefined
                    }
                    if (res.message === 'Server Error') {
                        setErrorTitle(res.message)
                        setErrorPage(<InvalidUrl/>)
                        return undefined
                    }
                }
            }).catch(err => console.log(err));
            setLoading(true);
            setData2(dataUrl)
        };
        fetchData();
    }, []);

    useEffect(() => {
        dispatch(setData(data2))
    }, [data2]);

    useEffect(() => {
        if(!errorPage && loading){
            document.body.style.background = '#CCCCCC';
        } else {
            document.body.style.background = '#FFFFFF';
        }
    }, [loading]);


    const titleSEO = !builderHouse ?
        data2?.apartment?.housing_complex?.name
        :
        data2?.housing_complex?.name

    const descSEO = !builderHouse ?
        data2?.apartment?.housing_complex?.description.replace(/^\s*[\r\n]/gm, '').replace(/<\/?[^>]+>/g,'').slice(0, 220)
        :
        data2?.housing_complex?.description.replace(/^\s*[\r\n]/gm, '').replace(/<\/?[^>]+>/g,'').slice(0, 220)
    return (
        <>
            <Helmet title={errorPage ? 'Ошибка: ' + errorTitle : titleSEO} htmlAttributes={{lang: "en"}}>
                <meta property="og:title" content={titleSEO}/>
                <meta property="og:description" content={descSEO}/>
            </Helmet>
            {errorPage ?
                errorPage :
                <>
                    <div className={loading ? 'wrapper bg-[#FFFFFF] px-[102px] mx-auto max-md:max-w-[1280px] max-md:w-full max-md:px-[12px]' :
                            'wrapper w-full h-[100vh] flex items-center justify-center'}>
                        {!loading ? 'Загрузка' : null}
                        {(agent !== null || '') && (data2 !== undefined) ? <Header agent={data2.agent}/> : ''}
                        {data2 !== undefined && <Main data={data2} builder={builderHouse} house={house}/>}
                    </div>
                    <div
                        className={'wrapper px-[102px] bg-[#F2F2F2] mx-auto max-md:max-w-[1280px] max-md:w-full max-md:px-[12px]'}>
                        {(agent !== null || '') && (data2 !== undefined) ? <Footer agent={data2.agent}/> : ''}
                    </div>
                    <YandexMetrik/>
                </>
            }
        </>
    );
}

export default App;
