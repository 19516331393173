import { createSlice } from '@reduxjs/toolkit';
import {RootState} from "../store";

interface DataState {
    dataReadyIcons: any;
}
const initialState: DataState = {
    dataReadyIcons: ''
}
const dataStaticReady = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setDataStaticReadyIcons(state, action) {
            state.dataReadyIcons = action.payload;
        },
    },
});
export const { setDataStaticReadyIcons } = dataStaticReady.actions;
export default dataStaticReady.reducer;

export const dataReady = (state: RootState) => state.dataReadyIcons;
