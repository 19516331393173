import { createSlice } from '@reduxjs/toolkit';
import {RootState} from "../store";

interface DataState {
    dataStatic: any;
}
const initialState: DataState = {
    dataStatic: ''
}
const dataStatic = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setDataStatic(state, action) {
            state.dataStatic = action.payload;
        },
    },
});
export const { setDataStatic } = dataStatic.actions;
export default dataStatic.reducer;

export const apiData = (state: RootState) => state.dataStatic;

