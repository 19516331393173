import React from 'react';

const SectionFeatures = ({data}:any) => {
    const items = data.replaceAll(/(<\/*ul>|\t|<\/li>)/g, '').split('<li>');

    return (
        <section className={'flex flex-col'}>
            <hr className={'my-[0px] pb-[80px] max-md:pb-[24px]'}/>
            <h2 className={'mb-[30px] leading-[44px] font-[600] text-[36px] max-md:text-[22px] max-md:mb-[15px] text-[#151515]'}>Преимущества</h2>
            <div>
                <ul className={'check_ul grid grid-cols-2 max-md:grid-cols-1 gap-[8px] max-md:text-[16px] text-[#4D4D4D] text-[18px] font-[400] leading-[26px]'}>
                    {items?.map((item: any) => (
                        item != '\r\n' ? <li key={item} dangerouslySetInnerHTML={{__html: item}}/> : null
                    ))}
                </ul>
            </div>
            <hr className={'my-[0px] mt-[40px] max-md:mt-[24px]'}/>
        </section>
    );
};

export default SectionFeatures;