import React, {useEffect, useRef, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {EffectFade, Navigation, Pagination} from 'swiper/modules';

const SectionVariants = ({data, title}:any) => {

    const mediaMobile = window.matchMedia('(max-width: 768px)').matches === true;
    const refVariants = useRef<HTMLDivElement>(null);

    const [activePage, setActivePage] = useState<number>(1)

    const setCurrentPage = (e, num: number) => {
        const event = e.target as Element;

        const list = refVariants.current?.querySelectorAll('.select-variants');
        list?.forEach(el => el.classList.toggle('active', false));

        if(!event.classList.contains('active')) {
            event?.classList.toggle('active');
            setActivePage(num);
        }
    }

    useEffect(() => {
        if(mediaMobile){
            refVariants.current?.children[0].classList.toggle('active')
        }
    }, []);

    return (
        <section>
            <h2 className={'mb-[30px] leading-[44px] font-[600] text-[36px] max-md:text-[22px] max-md:mb-[15px] text-[#151515]'}>{title}</h2>
            {
                mediaMobile ?
                    <div className={'mobile-variant grid grid-cols-2 max-md:grid-cols-1'}>
                        {
                            mediaMobile &&
                            <div className={'flex flex-row  border-b-2 border-gray-100 mb-[16px]'} ref={refVariants}>
                                {
                                    data?.map((_:any, _idx:number) => (
                                        <span key={_idx + 1} className={'select-variants px-[12px] text-center min-w-[40px] font-normal cursor-pointer'}
                                              onClick={(e) => setCurrentPage(e, _idx + 1)}
                                        >
                                            {data[_idx].date ? data[_idx].date.split('.')[2] : _idx + 1}
                                        </span>
                                    ))
                                }
                            </div>
                        }
                        {data?.map((item: any) => (
                            item.id === activePage &&
                            <>
                                <Swiper
                                    key={item.id}
                                    navigation={true}
                                    effect={'fade'}
                                    spaceBetween={50}
                                    slidesPerView={1}
                                    pagination={{
                                        type: 'fraction',
                                    }}
                                    modules={[EffectFade, Pagination, Navigation]}>
                                    {item.images?.map((image: any, _idx:number) => (
                                        <SwiperSlide key={_idx}>
                                            <img src={image.links.large} alt={image.id}/>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div className={'border border-t-[0px] border-[#E6E6E6] rounded-b-[14px]'}>
                                    <p className={'px-[12px] py-[12px] max-md:font-semibold text-[16px]'}>{item.name ? item.name :
                                        new Date(item.date.split('.').reverse()).toLocaleString('default', { month: 'long' }).charAt(0).toUpperCase() +
                                        new Date(item.date.split('.').reverse()).toLocaleString('default', { month: 'long' }).slice(1)
                                        + ' ' + item.date.split('.')[2]
                                    }</p>
                                </div>
                            </>
                        ))}
                    </div> :
                    <div className={'grid grid-cols-2 max-md:grid-cols-1 gap-[24px]'}>
                        {data?.map((item: any) => (
                            <Swiper
                                key={item.id}
                                navigation={true}
                                effect={'fade'}
                                spaceBetween={50}
                                slidesPerView={1}
                                pagination={{
                                    type: 'fraction',
                                }}
                                modules={[EffectFade, Pagination, Navigation]}>
                                <span
                                    className={'absolute z-[9] top-[32px] left-[24px] px-[12px] py-[8px] bg-[#ffffff] rounded-lg'}>{item.name ? item.name : item.date}</span>
                                {item.images?.map((image: any, _idx:number) => (
                                    <SwiperSlide key={_idx}>
                                        <img src={image.links.large} alt={image.id}/>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        ))}
                    </div>
            }
        </section>
    );
};

export default SectionVariants;