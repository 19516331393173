import '../../styles/pdf.css'
import React, {forwardRef, ReactNode, useEffect, useRef,} from "react";
import {useAppSelector} from "../../hooks";
import {convertToRoman, formatInternationalPhoneNumber, formatPrice, getNoun, getQuarter} from "../helpers";
import {useSearchParams} from "react-router-dom";
import {defaultIcon} from "../map/mapData";

interface Props {
    ref: ReactNode,
}

const Pdf = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {data} = useAppSelector(state => state.dataSlice);
    const {dataStatic} = useAppSelector(state => state.dataStatic);
    const {dataReadyIcons} = useAppSelector(state => state.dataReadyIcons);

    const [searchParams, setSearchParams] = useSearchParams();
    const builderHouse = searchParams.get('house');
    const builderHC = searchParams.get('builder');

    const house = data.housing_complex || data.apartment.housing_complex;
    const agent = data.agent;
    const images = house.images;

    const phone = agent.phone !== null ? formatInternationalPhoneNumber(agent.phone, 'RU') : null;
    const year = house.deadline ? new Date(house.deadline).getFullYear() : null;
    const featuresItems = house.advantages ? house.advantages.replaceAll(/(<\/*ul>|\t|<\/li>)/g, '').split('<li>') : null;

    let estate:any;
    const quarterDate = house.deadline && getQuarter(house.deadline);

    if(builderHouse){
        estate = data.apartment.estate ? data.apartment.estate : null;
    }

    const locationBlock = useRef<HTMLDivElement>(null);
    const locationRef = useRef<HTMLParagraphElement>(null);
    const aboutLocationParagraph = useRef<HTMLParagraphElement>(null);
    const refFirstPar = useRef<HTMLParagraphElement>(null);

    let wrappeedDescription:any;
    if(house.description && house.description.length > 550){
        wrappeedDescription = house.description.split(/\r?\n/);
    }

    let filterText:any;
    let firstText:any;
    let firstTextMixed:any;
    let aboutLocationText:any;
    let endingText:any;
    let symbolNum:any;
    let checkMixed = house.advantages && house.description;

    if(wrappeedDescription){
        filterText = wrappeedDescription.filter((el:any) => el !== '' && el.length > 50);

        firstText = checkWhiteSpaceAll(0, 550, firstText);

        if(checkMixed){
            firstTextMixed = checkWhiteSpaceAll(0, 250, firstTextMixed);
        } else {
            firstText = checkWhiteSpaceAll(0, 550, firstText);
        }

        if(checkMixed){
            aboutLocationText = checkWhiteSpaceAll(symbolNum,1500, aboutLocationText);
        } else {
            aboutLocationText = checkWhiteSpaceAll(symbolNum,1800, aboutLocationText);
        }
        endingText = checkWhiteSpaceAll(symbolNum,3300, endingText);
    }

    function checkWhiteSpaceAll(start:number, end: number, variable:any) {
        const string = filterText.join('');
        for(let i=end; i < string.length; i++){
            let check: any;
            check = endsWithAny(['.'],filterText.join('').slice(0,i));

            if(check){
                variable = filterText.join('').slice(start,i)
                symbolNum = i;
                return variable;
            }
        }
    }

    function endsWithAny(suffixes:any, string:string) {
        return suffixes.some(function (suffix:any) {
            return string.endsWith(suffix);
        });
    }

    function countPagePdf(){
        const pages = (document.querySelector('.pdf-container') as HTMLElement);
        if(pages){
            for (let i=0; i < pages.children.length; i++){
                const currentPageNodes = pages.children[i].children;
                const num = currentPageNodes[currentPageNodes.length - 1];
                if(num.className === 'num'){
                    num.innerHTML = (1 + i).toString();
                }
            }
        }
    }

    useEffect(() => {
        countPagePdf();
    }, [ref]);

    return (
        <div ref={ref} className={'pdf-container'}>
            <div className="item">
                {
                    images[0] ? <div className="main-photo"
                                     style={{
                                         backgroundImage: `url(${images[0] ? images[0].links.original : null})`,
                                         backgroundSize: "cover",
                                         backgroundRepeat: "no-repeat",
                                         backgroundPosition: "center center"
                                     }}>
                        </div>
                        : null
                }
                <div className="main-desc px-16 mb-12 w-full">
                    {
                        house.name ?
                            <h3 className="main-desc__title mb-[20px] text-[#151515] w-full">
                                {house.name}
                            </h3>
                            : null
                    }
                    {
                        house.class ?
                            <div className="main-desc__class mb-4">
                                <p className="main-desc__class-text main text-[#151515] leading-[26px]">
                                    {house.class}
                                </p>
                                <p className="main-desc__class-desc desc text-[#151515] leading-[26px]">
                                    Класс ЖК
                                </p>
                            </div> : null
                    }
                    {
                        house.deadline ?
                            <div className="main-desc__deadline mb-4">
                                <p className="main-desc__deadline-text main text-[#151515] leading-[26px]">
                                    {convertToRoman(quarterDate)} кв. {year} г.
                                </p>
                                <p className="main-desc__deadline-desc desc text-[#151515] leading-[26px]">
                                    Срок сдачи
                                </p>
                            </div> : null
                    }
                    {
                        house.district ?
                            <div className="main-desc__district mb-4">
                                <p className="main-desc__district-text main">
                                    {house.district.name}
                                </p>
                                <p className="main-desc__district-desc desc ">
                                    Район
                                </p>
                            </div> : null
                    }
                </div>
            </div>
            {(house.description && house.advantages) ? (
                // <div className="item-location mixed">
                //     <div className="item-head">
                //         {
                //             house.name ?
                //                 <h3 className="item-head__title">
                //                     {house.name}
                //                 </h3> : null
                //         }
                //         <div className="head-contacts">
                //             <p className="head-contacts__agent mr-6">
                //                 {agent.last_name ? agent.last_name : null} <br/>
                //                 {agent.first_name ? agent.first_name : null}
                //             </p>
                //             <div className="head-contacits__group">
                //                 <p className="head-contacts__tel">
                //                     {phone ? phone : null}
                //                 </p>
                //                 <a className="head-contacts__mail">
                //                     {agent.email ? agent.email : null}
                //                 </a>
                //             </div>
                //         </div>
                //     </div>
                //     <div className="item-photos">
                //         <div className="item-photos__elem">
                //             <div className="big-photo1 rounded-[10px] h-[250px] w-full" style={{
                //                 backgroundImage: `url(${images[1] ? images[1].links.original : null})`,
                //                 backgroundSize: "cover",
                //                 backgroundRepeat: "no-repeat",
                //                 backgroundPosition: "center center"
                //             }}>
                //             </div>
                //             <div className="lists">
                //                 {
                //                     house.advantages ?
                //                         <ul className={`check_ul-pdf grid grid-cols-2 gap-[8px] text-[#4D4D4D] font-[400] leading-[16px] mt-[30px]`}>
                //                             {featuresItems?.map((item: any) => (
                //                                 item != '\r\n' ?
                //                                     <li key={item} dangerouslySetInnerHTML={{__html: item}}
                //                                     /> : null
                //                             ))}
                //                         </ul>
                //                         : null
                //                 }
                //             </div>
                //         </div>
                //         <div className="item-photos__elem">
                //             <div className="big-photo2 rounded-[10px] h-[250px] w-full" style={{
                //                 backgroundImage: `url(${images[2] ? images[2].links.original : null})`,
                //                 backgroundSize: "cover",
                //                 backgroundRepeat: "no-repeat",
                //                 backgroundPosition: "center center"
                //             }}>
                //             </div>
                //             <p className="main-photo__desc max-h-[200px] overflow-hidden text-justify"
                //                dangerouslySetInnerHTML={{__html: checkMixed ? firstTextMixed : firstText}}>
                //             </p>
                //         </div>
                //     </div>
                //     <div className="num"></div>
                // </div>

                house.advantages ? <div className="item-location preferences">
                        <div className="item-head">
                            {
                                house.name ?
                                    <h3 className="item-head__title">
                                        {house.name}
                                    </h3> : null
                            }
                            <div className="head-contacts">
                                <p className="head-contacts__agent mr-6">
                                    {agent.last_name ? agent.last_name : null} <br/>
                                    {agent.first_name ? agent.first_name : null}
                                </p>
                                <div className="head-contacits__group">
                                    <p className="head-contacts__tel">
                                        {phone ? phone : null}
                                    </p>
                                    <a className="head-contacts__mail">
                                        {agent.email ? agent.email : null}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="item-photos">
                            <div className="photos">
                                <div className="big-photo1 rounded-[10px] h-[250px] w-full" style={{
                                    backgroundImage: `url(${images[1] ? images[1].links.original : null})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center"
                                }}>
                                </div>
                                <div className="big-photo2 rounded-[10px] h-[250px] w-full" style={{
                                    backgroundImage: `url(${images[2] ? images[2].links.original : null})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center"
                                }}>
                                </div>
                            </div>
                            {
                                house.advantages ?
                                    <div className={'mt-[20px]'}>
                                        <ul className={`check_ul-pdf grid grid-cols-${Math.floor((featuresItems?.length / 4))} grid-rows-4 gap-[8px] max-md:text-[16px] text-[#4D4D4D] font-[400] leading-[26px]`}>
                                            {featuresItems?.map((item: any) => (
                                                item != '\r\n' ?
                                                    <li key={item} dangerouslySetInnerHTML={{__html: item}}
                                                    /> : null
                                            ))}
                                        </ul>
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="num"></div>
                    </div> : null
            ) : (
                (house.advantages && !house.description) ? (
                    house.advantages ? <div className="item-location preferences">
                        <div className="item-head">
                            {
                                house.name ?
                                    <h3 className="item-head__title">
                                        {house.name}
                                    </h3> : null
                            }
                            <div className="head-contacts">
                                <p className="head-contacts__agent mr-6">
                                    {agent.last_name ? agent.last_name : null} <br/>
                                    {agent.first_name ? agent.first_name : null}
                                </p>
                                <div className="head-contacits__group">
                                    <p className="head-contacts__tel">
                                        {phone ? phone : null}
                                    </p>
                                    <a className="head-contacts__mail">
                                        {agent.email ? agent.email : null}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="item-photos">
                            <div className="photos">
                                <div className="big-photo1 rounded-[10px] h-[250px] w-full" style={{
                                    backgroundImage: `url(${images[1] ? images[1].links.original : null})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center"
                                }}>
                                </div>
                                <div className="big-photo2 rounded-[10px] h-[250px] w-full" style={{
                                    backgroundImage: `url(${images[2] ? images[2].links.original : null})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center"
                                }}>
                                </div>
                            </div>
                            {
                                house.advantages ?
                                    <div className={'mt-[20px]'}>
                                        <ul className={`check_ul-pdf grid grid-cols-${Math.floor((featuresItems?.length / 4))} grid-rows-4 gap-[8px] max-md:text-[16px] text-[#4D4D4D] font-[400] leading-[26px]`}>
                                            {featuresItems?.map((item: any) => (
                                                item != '\r\n' ?
                                                    <li key={item} dangerouslySetInnerHTML={{__html: item}}
                                                    /> : null
                                            ))}
                                        </ul>
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="num"></div>
                    </div> : null
                ) : (
                    // <div className="item-location">
                    //     <div className="item-head">
                    //         {
                    //             house.name ?
                    //                 <h3 className="item-head__title">
                    //                     {house.name}
                    //                 </h3> : null
                    //         }
                    //         <div className="head-contacts">
                    //             <p className="head-contacts__agent mr-6">
                    //                 {agent.last_name ? agent.last_name : null} <br/>
                    //                 {agent.first_name ? agent.first_name : null}
                    //             </p>
                    //             <div className="head-contacits__group">
                    //                 <p className="head-contacts__tel">
                    //                     {phone ? phone : null}
                    //                 </p>
                    //                 <a className="head-contacts__mail">
                    //                     {agent.email ? agent.email : null}
                    //                 </a>
                    //             </div>
                    //         </div>
                    //     </div>
                    //     {(!images[1] && !images[2]) ? (
                    //         <div className="item-photos">
                    //             <div className="item-photos__elem flex flex-row gap-[20px]">
                    //                 <p ref={refFirstPar} className="main-photo__desc text-[14px] text-justify"
                    //                    dangerouslySetInnerHTML={{__html: checkMixed ? firstTextMixed : firstText}}></p>
                    //             </div>
                    //         </div>
                    //     ) : (
                    //         (!images[1] || !images[2]) ? (
                    //             <div className="item-photos">
                    //                 <div className="item-photos__elem flex flex-row gap-[20px]">
                    //                     <div className="big-photo1 rounded-[10px] h-[250px] w-full"
                    //                          style={{
                    //                              backgroundImage: `url(${images[1] ? images[1].links.original : null})`,
                    //                              backgroundSize: "cover",
                    //                              backgroundRepeat: "no-repeat",
                    //                              backgroundPosition: "center center"
                    //
                    //                          }}>
                    //                     </div>
                    //                     <div className="big-photo2 rounded-[10px] h-[250px] w-full"
                    //                          style={{
                    //                              backgroundImage: `url(${images[2] ? images[2].links.original : null})`,
                    //                              backgroundSize: "cover",
                    //                              backgroundRepeat: "no-repeat",
                    //                              backgroundPosition: "center center"
                    //                          }}>
                    //                     </div>
                    //                 </div>
                    //                 <div className="item-photos__elem flex flex-row gap-[20px]">
                    //                     <p ref={refFirstPar} className="main-photo__desc text-[14px] text-justify"
                    //                        dangerouslySetInnerHTML={{__html: checkMixed ? firstTextMixed : firstText}}>
                    //                     </p>
                    //                 </div>
                    //             </div>
                    //         ) : (
                    //             <div className="item-photos">
                    //                 <div className="item-photos__elem flex flex-row gap-[20px]">
                    //                     <div className="big-photo1 rounded-[10px] h-[250px] w-full"
                    //                          style={{
                    //                              backgroundImage: `url(${images[1] ? images[1].links.original : null})`,
                    //                              backgroundSize: "cover",
                    //                              backgroundRepeat: "no-repeat",
                    //                              backgroundPosition: "center center"
                    //
                    //                          }}>
                    //                     </div>
                    //                     <div className="big-photo2 rounded-[10px] h-[250px] w-full"
                    //                          style={{
                    //                              backgroundImage: `url(${images[2] ? images[2].links.original : null})`,
                    //                              backgroundSize: "cover",
                    //                              backgroundRepeat: "no-repeat",
                    //                              backgroundPosition: "center center"
                    //                          }}>
                    //                     </div>
                    //                 </div>
                    //                 <div className="item-photos__elem flex flex-row gap-[20px]">
                    //                     <p ref={refFirstPar} className="main-photo__desc text-[14px] text-justify"
                    //                        dangerouslySetInnerHTML={{__html: checkMixed ? firstTextMixed : firstText}}>
                    //                     </p>
                    //                 </div>
                    //             </div>
                    //         )
                    //     )}
                    //     <div className="num"></div>
                    // </div>
                    house.advantages ? <div className="item-location preferences">
                        <div className="item-head">
                            {
                                house.name ?
                                    <h3 className="item-head__title">
                                        {house.name}
                                    </h3> : null
                            }
                            <div className="head-contacts">
                                <p className="head-contacts__agent mr-6">
                                    {agent.last_name ? agent.last_name : null} <br/>
                                    {agent.first_name ? agent.first_name : null}
                                </p>
                                <div className="head-contacits__group">
                                    <p className="head-contacts__tel">
                                        {phone ? phone : null}
                                    </p>
                                    <a className="head-contacts__mail">
                                        {agent.email ? agent.email : null}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="item-photos">
                            <div className="photos">
                                <div className="big-photo1 rounded-[10px] h-[250px] w-full" style={{
                                    backgroundImage: `url(${images[1] ? images[1].links.original : null})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center"
                                }}>
                                </div>
                                <div className="big-photo2 rounded-[10px] h-[250px] w-full" style={{
                                    backgroundImage: `url(${images[2] ? images[2].links.original : null})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center"
                                }}>
                                </div>
                            </div>
                            {
                                house.advantages ?
                                    <div className={'mt-[20px]'}>
                                        <ul className={`check_ul-pdf grid grid-cols-${Math.floor((featuresItems?.length / 4))} grid-rows-4 gap-[8px] max-md:text-[16px] text-[#4D4D4D] font-[400] leading-[26px]`}>
                                            {featuresItems?.map((item: any) => (
                                                item != '\r\n' ?
                                                    <li key={item} dangerouslySetInnerHTML={{__html: item}}
                                                    /> : null
                                            ))}
                                        </ul>
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="num"></div>
                    </div> : null
                )
            )}
            {/*{*/}
            {/*    aboutLocationText ? <div ref={locationBlock} className="item-location-hide">*/}
            {/*        <div className="item-head">*/}
            {/*            <h3 className="item-head__title">*/}
            {/*                О локации*/}
            {/*            </h3>*/}
            {/*            <div className="head-contacts">*/}
            {/*                <p className="head-contacts__agent mr-6">*/}
            {/*                    {agent.last_name ? agent.last_name : null} <br/>*/}
            {/*                    {agent.first_name ? agent.first_name : null}*/}
            {/*                </p>*/}
            {/*                <div className="head-contacits__group">*/}
            {/*                    <p className="head-contacts__tel">*/}
            {/*                        {phone ? phone : null}*/}
            {/*                    </p>*/}
            {/*                    <a className="head-contacts__mail">*/}
            {/*                        {agent.email ? agent.email : null}*/}
            {/*                    </a>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className={images[4] ? "item-content" : "item-content-flex"}>*/}
            {/*            {*/}
            {/*                images[4] ?*/}
            {/*                    <div className="big-photo1 rounded-[10px] w-full h-[250px]"*/}
            {/*                         style={{*/}
            {/*                             backgroundImage: `url(${images[4] ? images[4].links.original : null})`,*/}
            {/*                             backgroundSize: "cover",*/}
            {/*                             backgroundRepeat: "no-repeat",*/}
            {/*                             backgroundPosition: "center center"*/}
            {/*                         }}>*/}
            {/*                    </div> : null*/}
            {/*            }*/}
            {/*            {*/}
            {/*                aboutLocationText ?*/}
            {/*                    <div className="item-content__texts">*/}
            {/*                        <p ref={aboutLocationParagraph}*/}
            {/*                           className="item-content__text max-h-[500px] overflow-hidden text-justify"*/}
            {/*                           dangerouslySetInnerHTML={{__html: aboutLocationText}}>*/}
            {/*                        </p>*/}
            {/*                    </div> : null*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*        <div className="num"></div>*/}
            {/*    </div> : null*/}
            {/*}*/}
            {/*{*/}
            {/*    endingText ? <div className="item-location-v2">*/}
            {/*        <div className="item-head">*/}
            {/*            {*/}
            {/*                house.name ?*/}
            {/*                    <h3 className="item-head__title">*/}
            {/*                        {house.name}*/}
            {/*                    </h3> : null*/}
            {/*            }*/}
            {/*            <div className="head-contacts">*/}
            {/*                <p className="head-contacts__agent mr-6">*/}
            {/*                    {agent.last_name ? agent.last_name : null} <br/>*/}
            {/*                    {agent.first_name ? agent.first_name : null}*/}
            {/*                </p>*/}
            {/*                <div className="head-contacits__group">*/}
            {/*                    <p className="head-contacts__tel">*/}
            {/*                    {phone ? phone : null}*/}
            {/*                    </p>*/}
            {/*                    <a className="head-contacts__mail">*/}
            {/*                        {agent.email ? agent.email : null}*/}
            {/*                    </a>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="item-content">*/}
            {/*            <p ref={locationRef}*/}
            {/*               className="item-content__text text-[16px] max-h-[500px] overflow-hidden text-justify"*/}
            {/*               dangerouslySetInnerHTML={{__html: endingText}}>*/}
            {/*            </p>*/}
            {/*        </div>*/}
            {/*        <div className="num"></div>*/}
            {/*    </div> : null*/}
            {/*}*/}
            <div className="item-object">
                <div className="item-head">
                    <h3 className="item-head__title">
                        Об объекте
                    </h3>
                    <div className="head-contacts">
                        <p className="head-contacts__agent mr-6">
                            {agent.last_name ? agent.last_name : null} <br/>
                            {agent.first_name ? agent.first_name : null}
                        </p>
                        <div className="head-contacits__group">
                            <p className="head-contacts__tel">
                                {phone ? phone : null}
                            </p>
                            <a className="head-contacts__mail">
                                {agent.email ? agent.email : null}
                            </a>
                        </div>
                    </div>
                </div>
                {(!images[12] && !images[8]) ? (
                    <div className={"item-content item-photo-only"}>
                        <div className="item-object__photo">
                            <div className="object-params">
                                <ul className="object-list">
                                    <li className="object-list__item">
                                        <p className="object-list__item-text">
                                            {house.price_min ? formatPrice(house.price_min) : '-'} – {house.price_min ? formatPrice(house.price_max) : '-'}
                                        </p>
                                        <span className="object-list__item-desc">Цены</span>
                                    </li>
                                    <li className="object-list__item">
                                        <p className="object-list__item-text">
                                            {house.price_m2_min ? formatPrice(house.price_m2_min) : '-'} – {house.price_m2_max ? formatPrice(house.price_m2_max) : '-'}
                                        </p>
                                        <span className="object-list__item-desc">Цена за м²</span>
                                    </li>
                                </ul>
                                <ul className="object-list">
                                    {
                                        house.deadline ?
                                            <li className="object-list__item">
                                                <p className="object-list__item-text">
                                                    {convertToRoman(quarterDate)} кв. {year} г.
                                                </p>
                                                <span className="object-list__item-desc">Срок выдачи ключей</span>
                                            </li> : null
                                    }
                                    <li className="object-list__item">
                                        <p className="object-list__item-text">
                                            {house.total_area_min ? house.total_area_min : '-'} м²
                                            – {house.total_area_max ? house.total_area_max : '-'} м²
                                        </p>
                                        <span className="object-list__item-desc">
                                        Площади квартир
                                        </span>
                                    </li>
                                    {
                                        house.type ?
                                            <li className="object-list__item">
                                                <p className="object-list__item-text">
                                                    {house.type}
                                                </p>
                                                <span className="object-list__item-desc">
                                                        Тип ЖК
                                                    </span>
                                            </li> : null
                                    }
                                </ul>
                            </div>
                            <div className="object-params">
                                <ul className="object-list">
                                    {
                                        builderHouse !== null && house.decorations.length ?
                                            <li className="object-list__item">
                                                <p className="object-list__item-text">
                                                    {house.decorations.length + ' ' + getNoun(house.decorations.length, 'вид', 'вида', 'видов')}
                                                </p>
                                                <span className="object-list__item-desc">
                                                    Отделка
                                                </span>
                                            </li> : null
                                    }
                                    {
                                        house.total_apartments ?
                                            <li className="object-list__item">
                                                <p className="object-list__item-text">
                                                    {house.total_apartments}
                                                </p>
                                                <span className="object-list__item-desc">
                                                    Квартир в продаже
                                                </span>
                                            </li> : null
                                    }
                                    {
                                        house.floor_max ?
                                            <li className="object-list__item">
                                                {house.floor_min ?
                                                    <p className="object-list__item-text">
                                                        {house.floor_min} – {house.floor_max}
                                                    </p> :
                                                    <p className="object-list__item-text">
                                                        {house.floor_max}
                                                    </p>
                                                }
                                                <span className="object-list__item-desc">
                                                    Этажность
                                                </span>
                                            </li> : null
                                    }
                                </ul>
                                <ul className="object-list">
                                    {
                                        house.ceiling_height ?
                                            <li className="object-list__item">
                                                <p className="object-list__item-text">
                                                    {house.ceiling_height}
                                                </p>
                                                <span className="object-list__item-desc">Потолки</span>
                                            </li> : null
                                    }
                                    {
                                        house.balcony_area !== null && house.loggia ?
                                            <li className="object-list__item">
                                                <p className="object-list__item-text">
                                                    {house.loggia ? house.loggia + 'м² (утеплена)' : null}
                                                    {house.balcony_area !== null ? `${house?.balcony_area} м<sup>2</sup> (утеплена)` : '-'}
                                                </p>
                                                <span className="object-list__item-desc">Лоджия</span>
                                            </li>
                                            : null
                                    }
                                    {
                                        house.total_estates ?
                                            <li className="object-list__item">
                                                <p className="object-list__item-text">
                                                    {house.total_estates}
                                                </p>
                                                <span className="object-list__item-desc">
                                                    Количество корпусов
                                                </span>
                                            </li> : null
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                ) : (
                    (!images[12] || !images[8]) ? (
                        <div className={"item-content item-content-flex"}>
                            <div className="item-object__photo w-[50%]">
                                {images[12] ?
                                    <div className="item-object__photos-photo rounded-[10px] h-[250px] w-full"
                                         style={{
                                             backgroundImage: `url(${images[12] ? images[12].links.original : null})`,
                                             backgroundSize: "cover",
                                             backgroundRepeat: "no-repeat",
                                             backgroundPosition: "center center"
                                         }}>
                                    </div>
                                    : null}
                                {
                                    images[8] ?
                                        <div className="item-object__photos-photo rounded-[10px] h-[250px] w-full"
                                             style={{
                                                 backgroundImage: `url(${images[8] ? images[8].links.original : null})`,
                                                 backgroundSize: "cover",
                                                 backgroundRepeat: "no-repeat",
                                                 backgroundPosition: "center center"
                                             }}>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className="item-object__photo">
                                <div className="object-params">
                                    <ul className="object-list">
                                        <li className="object-list__item">
                                            <p className="object-list__item-text">
                                                {house.price_min ? formatPrice(house.price_min) : '-'} – {house.price_min ? formatPrice(house.price_max) : '-'}
                                            </p>
                                            <span className="object-list__item-desc">Цены</span>
                                        </li>
                                        <li className="object-list__item">
                                            <p className="object-list__item-text">
                                                {house.price_m2_min ? formatPrice(house.price_m2_min) : '-'} – {house.price_m2_max ? formatPrice(house.price_m2_max) : '-'}
                                            </p>
                                            <span className="object-list__item-desc">Цена за м²</span>
                                        </li>
                                    </ul>
                                    <ul className="object-list">
                                        {
                                            house.deadline ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {convertToRoman(quarterDate)} кв. {year} г.
                                                    </p>
                                                    <span
                                                        className="object-list__item-desc">Срок выдачи ключей
                                                    </span>
                                                </li> : null
                                        }
                                        <li className="object-list__item">
                                            <p className="object-list__item-text">
                                                {house.total_area_min ? house.total_area_min : '-'} м²
                                                – {house.total_area_max ? house.total_area_max : '-'} м²
                                            </p>
                                            <span className="object-list__item-desc">
                                                Площади квартир
                                            </span>
                                        </li>
                                        {
                                            house.type ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.type}
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                        Тип ЖК
                                                    </span>
                                                </li> : null
                                        }
                                    </ul>
                                </div>
                                <div className="object-params">
                                    <ul className="object-list">
                                        {
                                            builderHouse !== null && house.decorations.length ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.decorations.length + ' ' + getNoun(house.decorations.length, 'вид', 'вида', 'видов')}
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                        Отделка
                                                    </span>
                                                </li> : null
                                        }
                                        {
                                            house.total_apartments ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.total_apartments}
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                        Квартир в продаже
                                                    </span>
                                                </li> : null

                                        }
                                        {
                                            house.floor_max ?
                                                <li className="object-list__item">
                                                    {house.floor_min ?
                                                        <p className="object-list__item-text">
                                                            {house.floor_min} – {house.floor_max}
                                                        </p> :
                                                        <p className="object-list__item-text">
                                                            {house.floor_max}
                                                        </p>
                                                    }
                                                    <span className="object-list__item-desc">
                                                        Этажность
                                                    </span>
                                                </li> : null
                                        }
                                    </ul>
                                    <ul className="object-list">
                                        {
                                            house.ceiling_height ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.ceiling_height}
                                                    </p>
                                                    <span className="object-list__item-desc">Потолки</span>
                                                </li> : null
                                        }
                                        {
                                            house.balcony_area !== null && house.loggia ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.loggia ? house.loggia + 'м² (утеплена)' : null}
                                                        {house.balcony_area !== null ? `${house?.balcony_area} м<sup>2</sup> (утеплена)` : '-'}
                                                    </p>
                                                    <span className="object-list__item-desc">Лоджия</span>
                                                </li>
                                                : null
                                        }
                                        {
                                            house.total_estates ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.total_estates}
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                    Количество корпусов
                                                </span>
                                                </li> : null
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={"item-content"}>
                            <div className="item-object__photo">
                                {images[12] ?
                                    <div className="item-object__photos-photo rounded-[10px] h-[250px] w-full"
                                         style={{
                                             backgroundImage: `url(${images[12] ? images[12].links.original : null})`,
                                             backgroundSize: "cover",
                                             backgroundRepeat: "no-repeat",
                                             backgroundPosition: "center center"
                                         }}>
                                    </div>
                                    : null
                                }
                                <div className="object-params">
                                    <ul className="object-list">
                                        <li className="object-list__item">
                                            <p className="object-list__item-text">
                                                {house.price_min ? formatPrice(house.price_min) : '-'} – {house.price_min ? formatPrice(house.price_max) : '-'}
                                            </p>
                                            <span className="object-list__item-desc">Цены</span>
                                        </li>
                                        <li className="object-list__item">
                                            <p className="object-list__item-text">
                                                {house.price_m2_min ? formatPrice(house.price_m2_min) : '-'} – {house.price_m2_max ? formatPrice(house.price_m2_max) : '-'}
                                            </p>
                                            <span className="object-list__item-desc">Цена за м²</span>
                                        </li>
                                    </ul>
                                    <ul className="object-list">
                                        {
                                            house.deadline ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {convertToRoman(quarterDate)} кв. {year} г.
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                        Срок выдачи ключей
                                                    </span>
                                                </li> : null
                                        }
                                        <li className="object-list__item">
                                            <p className="object-list__item-text">
                                                {house.total_area_min ? house.total_area_min : '-'} м²
                                                – {house.total_area_max ? house.total_area_max : '-'} м²
                                            </p>
                                            <span className="object-list__item-desc">
                                                Площади квартир
                                            </span>
                                        </li>
                                        {
                                            house.type ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.type}
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                        Тип ЖК
                                                    </span>
                                                </li> : null
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="item-object__photo">
                                {
                                    images[8] ?
                                        <div className="item-object__photos-photo rounded-[10px] h-[250px] w-full"
                                             style={{
                                                 backgroundImage: `url(${images[8] ? images[8].links.original : null})`,
                                                 backgroundSize: "cover",
                                                 backgroundRepeat: "no-repeat",
                                                 backgroundPosition: "center center"
                                             }}>
                                        </div>
                                        : null
                                }
                                <div className="object-params">
                                    <ul className="object-list">
                                        {
                                            builderHouse !== null && house.decorations.length ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.decorations.length + ' ' + getNoun(house.decorations.length, 'вид', 'вида', 'видов')}
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                        Отделка
                                                    </span>
                                                </li> : null
                                        }
                                        {
                                            house.total_apartments ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.total_apartments}
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                        Квартир в продаже
                                                    </span>
                                                </li> : null

                                        }
                                        {
                                            house.floor_max ?
                                                <li className="object-list__item">
                                                    {house.floor_min ?
                                                        <p className="object-list__item-text">
                                                            {house.floor_min} – {house.floor_max}
                                                        </p> :
                                                        <p className="object-list__item-text">
                                                            {house.floor_max}
                                                        </p>
                                                    }
                                                    <span className="object-list__item-desc">
                                                        Этажность
                                                    </span>
                                                </li> : null
                                        }
                                    </ul>
                                    <ul className="object-list">
                                        {
                                            house.ceiling_height ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.ceiling_height}
                                                    </p>
                                                    <span className="object-list__item-desc">Потолки</span>
                                                </li> : null
                                        }
                                        {
                                            house.balcony_area !== null && house.loggia ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.loggia ? house.loggia + 'м² (утеплена)' : null}
                                                        {house.balcony_area !== null ? `${house?.balcony_area} м<sup>2</sup> (утеплена)` : '-'}
                                                    </p>
                                                    <span className="object-list__item-desc">Лоджия</span>
                                                </li>
                                                : null
                                        }
                                        {
                                            house.total_estates ?
                                                <li className="object-list__item">
                                                    <p className="object-list__item-text">
                                                        {house.total_estates}
                                                    </p>
                                                    <span className="object-list__item-desc">
                                                    Количество корпусов
                                                </span>
                                                </li> : null
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                )}
                <div className="num"></div>
            </div>
            {
                !builderHouse ?
                    null
                    :
                    <div className="item-flat">
                        <div className="item-head">
                            {
                                data.apartment.total_area ?
                                    <h3 className="item-head__title">
                                        Квартира {data.apartment.total_area} м²
                                    </h3> :
                                    <h3 className="item-head__title">
                                        Квартира
                                    </h3>
                            }
                            <div className="head-contacts">
                                <p className="head-contacts__agent mr-6">
                                    {agent.last_name ? agent.last_name : null} <br/>
                                    {agent.first_name ? agent.first_name : null}
                                </p>
                                <div className="head-contacits__group">
                                    <p className="head-contacts__tel">
                                        {phone ? phone : null}
                                    </p>
                                    <a className="head-contacts__mail">
                                        {agent.email ? agent.email : null}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="item-content">
                            <div className="item-pictures">
                                {
                                    data.apartment.flat_plan_image ?
                                        <div className="schema-flat" style={{
                                            backgroundImage: `url(${data.apartment.flat_plan_image.links.preview})`,
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center center",
                                            height: "100%"
                                        }}>
                                        </div> : null
                                }
                                {
                                    data.apartment.floor_plan_image ?
                                        <div className="item-pictures-sub">
                                            <div className="schema-floor"
                                                 style={{
                                                     backgroundImage: `url(${data.apartment.floor_plan_image.links.preview})`,
                                                     backgroundSize: "cover",
                                                     backgroundRepeat: "no-repeat",
                                                     backgroundPosition: "center center",
                                                 }}>
                                            </div>
                                        </div> : null
                                }
                            </div>
                            <div className="item-prices">
                                <div className="item-prices__total">
                                    {
                                        data.apartment.price ?
                                            <div className="item-prices__main">
                                            <span className="item-prices__total-price">
                                                {data.apartment.price ? formatPrice(data.apartment.price) : null}
                                            </span>
                                                <span className="item-prices__total-desc font-normal text-[#4D4D4D]">
                                                Цена
                                            </span>
                                            </div> : null
                                    }
                                    {
                                        data.apartment.price_m2 ?
                                            <div className="item-prices__sub">
                                            <span className="item-prices__sub-price">
                                               {data.apartment.price_m2 ? formatPrice(data.apartment.price_m2) : null}
                                            </span>
                                                <span className="item-prices__sub-desc">
                                                т. руб./м²
                                            </span>
                                            </div> : null
                                    }
                                </div>
                                <div className="item-params">
                                    <ul className="params-list first">
                                        {
                                            data.apartment.number != null && data.apartment.number !== '-' ?
                                                <li className="params-list__item">
                                                    <span className="params-list__item-text">
                                                        {data.apartment.number}
                                                    </span>
                                                    <span className="params-list__item-desc">Номер квартиры</span>
                                                </li> : null
                                        }
                                        {
                                            data.apartment.max_floor || data.apartment.floor ?
                                                <li className="params-list__item">
                                                    <span className="params-list__item-text">
                                                        {data.apartment?.max_floor > 0 ? `${data.apartment?.floor} из ${data.apartment?.max_floor}` : data.apartment.floor}
                                                    </span>
                                                    <span className="params-list__item-desc">Этаж</span>
                                                </li> : null
                                        }
                                        {
                                            data.apartment.entrance_number ?
                                                <li className="params-list__item">
                                                    <span className="params-list__item-text">{data.apartment.entrance_number.replace('Секция','')}</span>
                                                    <span className="params-list__item-desc">Секция</span>
                                                </li> : null
                                        }
                                        {
                                            data.apartment.total_area ?
                                                <li className="params-list__item">
                                                    <span className="params-list__item-text">
                                                        {data.apartment.total_area} м<sup>2</sup>
                                                    </span>
                                                    <span className="params-list__item-desc">Площадь общая</span>
                                                </li> : null
                                        }
                                        {
                                            data.apartment.living_area ?
                                                <li className="params-list__item">
                                                    <span className="params-list__item-text">{data.apartment.living_area} м<sup>2</sup></span>
                                                    <span className="params-list__item-desc">Площадь жилая</span>
                                                </li>
                                                : null
                                        }
                                        {
                                            data.apartment.kitchen_area ?
                                                <li className="params-list__item">
                                                    <span className="params-list__item-text">{data.apartment.kitchen_area} м<sup>2</sup></span>
                                                    <span className="params-list__item-desc">Площадь кухни</span>
                                                </li>
                                                : null
                                        }
                                        {
                                            house.deadline ?
                                                    <li className="params-list__item">
                                                        <span className="params-list__item-text">{convertToRoman(quarterDate)} кв. {year} года</span>
                                                        <span className="params-list__item-desc">Срок выдачи ключей</span>
                                                    </li> : null
                                        }
                                    </ul>
                                    <hr className="decorator"/>
                                    <ul className="params-list second">
                                        {
                                            data.ceiling_height ?
                                                <li className="params-list__item">
                                                    <span className="params-list__item-text">{data.ceiling_height}</span>
                                                    <span className="params-list__item-desc">Потолки</span>
                                                </li> : null
                                        }
                                        {
                                            data.apartment.loggia ?
                                                <li className="params-list__item">
                                                    <span className="params-list__item-text">
                                                        {data.apartment.loggia  !== null ? `${data.apartment.loggia} м<sup>2</sup> (утеплена)` : '-'}
                                                    </span>
                                                    <span className="params-list__item-desc">
                                                        Лоджия
                                                    </span>
                                                </li> : null
                                        }
                                        {
                                            estate?.elevator_types.length > 0 ?
                                                <li className="params-list__item alone">
                                                    <span className="params-list__item-text">{estate.elevator_types[0].name}</span>
                                                    <span className="params-list__item-desc">Лифт</span>
                                                </li> : null
                                        }
                                        {
                                            estate?.parking_types.length > 0 ?
                                                <li className="params-list__item alone">
                                                    <span className="params-list__item-text">{estate.parking_types[0].name}</span>
                                                    <span className="params-list__item-desc">Паркинг</span>
                                                </li> : null
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="num"></div>
                    </div>
            }
            <div className="item-infrastructure">
                <div className={'map-pdf'} style={{
                    backgroundImage: `url(${dataStatic})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center"
                }}>
                    <div className={'map-pdf-icons'} style={{
                        backgroundImage: `url(${dataReadyIcons})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        position: 'absolute',
                    }}>
                    </div>
                    {
                        <div className={'icon-place-container'}>
                            <img src={defaultIcon.iconImageHref}/>
                        </div>
                    }
                </div>
                <div className="map-content">
                    <div className="item-head__title map-title mb-[40px]">
                        <h2 className="map-title__content mb-7">
                            Инфраструктура
                        </h2>
                        <div className="map-title__address mb-[20px]">
                        {house.name ? house.name + ',' + ' ' : null}
                                {house.city.name ? house.city.name + ',' + ' ' : null}
                                {house.address ? house.address : null}
                            </div>
                            <div className={'flex flex-col gap-[8px]'}>
                                <div className={'flex flex-wrap gap-[10px]'}>
                                    {house.hc_metros?.map((hc_metro: any, _idx: number) => (
                                        <div key={hc_metro.id} className={'flex flex-row gap-[8px] items-center'}>
                                            {
                                                hc_metro.metro.color ?
                                                    <span style={{background: hc_metro.metro.color}}
                                                          className={`block w-[10px] h-[10px] rounded-[50%]`}/>
                                                    : null
                                            }
                                            <span
                                                className={'text-[16px] text-[#4D4D4D] leading-[24px] font-normal'}>{hc_metro.metro.name}</span>
                                            <span
                                                className={'flex flex-row gap-[4px] items-center text-[14px] text-[#A6A6A6] font-[400] leading-[18px]'}>
                                            {
                                                hc_metro.metro_infos[_idx] ?
                                                    <img
                                                        src={`icons/metros/${hc_metro.metro_infos[_idx]?.metro_way_alias}.svg`}
                                                        alt="metro-info"/> : null
                                            }
                                                {
                                                    hc_metro.metro_infos[0] ?
                                                        <span>{hc_metro.metro_infos[0].minutes} мин.</span> : null
                                                }
                                        </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="map-prefer mt-[65px]">
                            <ul className={'grid grid-cols-2 gap-[50px]'}>
                                {
                                    house.infrastructures?.map((el: any) =>
                                        <li key={el.id} className={'flex flex-row items-center'}>
                                            <img src={require(`../../svg/${el.icon}.svg`)} className={'mr-[12px]'}/>
                                            <p>{el.name}</p>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="num"></div>
            </div>
            {
                builderHC !== null && house.decorations.length > 0 ?
                    <div className="item-trim">
                    <div className="item-head">
                        <h3 className={'item-head__title'}>Варианты отделки</h3>
                        </div>
                        <div className="trim-content">
                            {
                                house.decorations?.map((el:any) => (
                                    <div key={el.id} className="trim-item">
                                        <div className="trim-photo rounded-[10px] h-[250px] w-full"
                                                style={{
                                                backgroundImage: `url(${el.images ? el.images[0].links.preview : null})`,
                                                backgroundSize: "cover",
                                                backgroundRepeat: "no-repeat",
                                                backgroundPosition: "center center",
                                            }}>
                                        </div>
                                        <div className="trim-item__text">
                                            <div className="trim-item__title">{el.type ? el.type : null}</div>
                                            <ul className="trim-list">
                                                <li className="trim-list__elem text-justify text-[14px]"
                                                    dangerouslySetInnerHTML={{__html: el.description.split('\r')[0]}}>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="num"></div>
                    </div> : null
            }
            {
                builderHC !== null && house.construction_phases.length > 0 &&
                    <div className="item-process">
                    <div className="item-head__title process-head mb-6">
                        Ход строительства
                    </div>
                    <div className="process-content">
                        {
                            house.construction_phases?.map((el:any) => (
                                <div key={el.id} className="process-item">
                                    <div className="process-photo rounded-[10px] h-[250px] w-full" style={{
                                        backgroundImage: `url(${el.images[0].links.large})`,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center center",
                                    }}>
                                    </div>
                                    <div className="process-item__text">
                                        <div className="process-item__title text-black text-[26px] font-bold">{el.date.split('.')[2]}</div>
                                        <div className="process-item__desc">
                                            {new Date(
                                                el.date.split('.').reverse()
                                            ).toLocaleString('default', { month: 'long' })}
                                        </div>
                                        {
                                            el.comment !== 'null' && el.comment ? <p className={'process-item__comment text-justify'}>
                                                {el.comment}
                                            </p> : null
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="num"></div>
                </div>
            }
            {
                // house.banking_accreditations.length ?
                //     <div className="item-banks">
                //         <div className="banks-head mb-6">
                //             Банковская аккредитация
                //         </div>
                //         <div className="banks-content">
                //             <div className="banks-item">
                //                 <div className="banks-item__group ml-3">
                //                     <span className="banks-item__group-bank"></span>
                //                     <span className="banks-item__group-desc"></span>
                //                 </div>
                //             </div>
                //         </div>
                //         <div className="num"></div>
                //     </div>
                //     : null
            }
            <div className="item-contacts v2">
                <h2 className="item-head__title head-contacts">
                    Контакты
                </h2>
                <div className={!agent.last_name && !agent.first_name ? "contacts-content h-full justify-center absolute w-full top-0 left-0" : 'contacts-content'}>
                    <div className="agent">
                        {
                            agent.image ? <div className="agent-photo rounded-[191.5px]"
                                               style={{
                                                   backgroundImage: `url(${agent.image.links.preview})`,
                                                   backgroundSize: "cover",
                                                   backgroundRepeat: "no-repeat",
                                                   backgroundPosition: "center center"
                                               }}>
                            </div>: null
                        }
                        <span className="agent-name">
                        {agent.last_name ? agent.last_name : null} {agent.first_name ? agent.first_name : null}
                    </span>
                    </div>
                    <div className="card">
                        {
                            house.developer ?
                                <div className="card-title">
                                    {/*<svg width="93" height="94" viewBox="0 0 93 94" fill="none"*/}
                                    {/*     xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*    <path*/}
                                    {/*        d="M36.4297 28.189H88.9625C83.7325 16.4473 73.7734 7.28585 61.5015 3.11719L36.4297 28.189Z"*/}
                                    {/*        fill="#E85222"/>*/}
                                    {/*    <path*/}
                                    {/*        d="M91.5386 35.5787H27.5123C26.0179 35.5787 24.671 34.6778 24.0987 33.2968C23.5272 31.9181 23.8429 30.3283 24.9007 29.2705L53.0608 1.11039C50.9416 0.812915 48.7804 0.648438 46.5788 0.648438C20.9581 0.648438 0.1875 21.4186 0.1875 47.0397C0.1875 50.4702 0.572757 53.8094 1.27854 57.0289H66.0003C67.493 57.0289 68.8421 57.9293 69.4118 59.3103C69.9855 60.6891 69.668 62.2797 68.612 63.3367L39.1237 92.8259C41.5509 93.2182 44.0398 93.4292 46.5788 93.4292C72.1999 93.4292 92.9683 72.6586 92.9683 47.0397C92.9683 43.0843 92.4713 39.2441 91.5386 35.5787Z"*/}
                                    {/*        fill="#E85222"/>*/}
                                    {/*    <path*/}
                                    {/*        d="M57.082 64.4219H3.57031C8.49397 76.5971 18.4269 86.2046 30.8225 90.6814L57.082 64.4219Z"*/}
                                    {/*        fill="#E85222"/>*/}
                                    {/*</svg>*/}
                                    <span className="card-title__name">
                                    {house.developer.name}
                                </span>
                                </div> : null
                        }
                        {
                            house.developer ? <div className="card-decorator"></div> : null
                        }

                        <div className="card-contacts">
                            {
                                !agent.last_name && !agent.first_name ?
                                    <div className="card-name mb-2">Симонова Марина</div> : null
                            }
                            <div className="card-tel mb-3">
                                <div className="card-tel__tel">{phone ? phone : null}</div>
                                <div className="card-tel__mail">{agent.email ? agent.email : null}</div>
                            </div>
                            <div className="card-social">
                                {/*<div className="card-social__item">*/}
                                {/*    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
                                {/*         xmlns="http://www.w3.org/2000/svg">*/}
                                {/*        <g clipPath="url(#clip0_6343_22324)">*/}
                                {/*            <path*/}
                                {/*                d="M5.448 0C4.92 0.024 4.224 0.072 3.912 0.144C3.432 0.24 2.976 0.384 2.616 0.576C2.16 0.792 1.776 1.08 1.44 1.416C1.08 1.776 0.792 2.16 0.576 2.616C0.384 2.976 0.24 3.432 0.144 3.912C0.072 4.224 0.024 4.92 0.024 5.448C0 5.664 0 5.952 0 6.096V17.904V18.5521C0.024 19.0801 0.072 19.7761 0.144 20.0881C0.24 20.5681 0.384 21.024 0.576 21.384C0.792 21.8401 1.08 22.2241 1.416 22.5601C1.776 22.9201 2.16 23.2081 2.616 23.4241C2.976 23.6161 3.432 23.7601 3.912 23.8561C4.224 23.9281 4.92 23.9761 5.448 23.9761C5.664 24.0001 5.952 24 6.096 24H17.904H18.552C19.08 23.976 19.776 23.9281 20.088 23.8561C20.568 23.7601 21.024 23.6161 21.384 23.4241C21.84 23.2081 22.224 22.9201 22.56 22.5841C22.92 22.2241 23.208 21.8401 23.424 21.384C23.616 21.024 23.76 20.5681 23.856 20.0881C23.928 19.7761 23.976 19.0801 23.976 18.5521C24 18.3361 24 18.048 24 17.904V5.448C23.976 4.92 23.928 4.224 23.856 3.912C23.76 3.432 23.616 2.976 23.424 2.616C23.208 2.16 22.92 1.776 22.584 1.44C22.224 1.08 21.84 0.792 21.384 0.576C21.024 0.384 20.568 0.24 20.088 0.144C19.776 0.072 19.08 0.024 18.552 0.024C18.336 -2.79397e-09 18.048 0 17.904 0H5.448Z"*/}
                                {/*                fill="url(#paint0_linear_6343_22324)"/>*/}
                                {/*            <path fillRule="evenodd" clipRule="evenodd"*/}
                                {/*                  d="M5.01555 11.539C9.04207 9.78467 11.7271 8.62813 13.0705 8.06934C16.9063 6.4739 17.7033 6.19675 18.2228 6.1876C18.3371 6.18558 18.5925 6.2139 18.758 6.34818C18.8978 6.46157 18.9362 6.61474 18.9546 6.72224C18.973 6.82974 18.9959 7.07463 18.9777 7.26598C18.7698 9.45002 17.8704 14.7501 17.4129 17.1963C17.2192 18.2314 16.838 18.5784 16.4689 18.6124C15.6668 18.6862 15.0577 18.0823 14.2809 17.573C13.0652 16.7762 12.3785 16.2801 11.1985 15.5025C9.83477 14.6038 10.7188 14.1099 11.496 13.3027C11.6994 13.0915 15.2334 9.87693 15.3018 9.58533C15.3104 9.54886 15.3183 9.41291 15.2375 9.34113C15.1568 9.26934 15.0376 9.29389 14.9516 9.31341C14.8296 9.34109 12.8876 10.6247 9.12552 13.1642C8.57429 13.5428 8.075 13.7272 7.62765 13.7175C7.13448 13.7069 6.18583 13.4387 5.48061 13.2094C4.61562 12.9283 3.92815 12.7796 3.98801 12.3021C4.01919 12.0534 4.3617 11.799 5.01555 11.539Z"*/}
                                {/*                  fill="white"/>*/}
                                {/*        </g>*/}
                                {/*        <defs>*/}
                                {/*            <linearGradient id="paint0_linear_6343_22324" x1="12" y1="24" x2="12"*/}
                                {/*                            y2="4.57764e-05" gradientUnits="userSpaceOnUse">*/}
                                {/*                <stop stopColor="#027FC6"/>*/}
                                {/*                <stop offset="1" stopColor="#0DACFF"/>*/}
                                {/*            </linearGradient>*/}
                                {/*            <clipPath id="clip0_6343_22324">*/}
                                {/*                <rect width="24" height="24" fill="white"/>*/}
                                {/*            </clipPath>*/}
                                {/*        </defs>*/}
                                {/*    </svg>*/}
                                {/*    <div className="card-socail__item-name">Telegram</div>*/}
                                {/*    <div className="card-socail__item-alt">нет данных</div>*/}
                                {/*</div>*/}
                                {
                                    phone ?
                                        <>
                                            <div className="card-social__item">
                                                <img src="icons/whatsapp.svg" alt="wa-icon"/>
                                                <>
                                                    <div className="card-socail__item-name">WhatsApp</div>
                                                    <div className="card-socail__item-alt">{phone}</div>
                                                </>
                                            </div>
                                            <div className="card-social__item">
                                                <img src="icons/viber.svg" alt="viber-icon"/>
                                                {
                                                    phone ?
                                                        <>
                                                            <div className="card-socail__item-name">Viber</div>
                                                            <div
                                                                className="card-socail__item-alt">{phone ? phone : null}</div>
                                                        </>
                                                        : null
                                                }
                                            </div>
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default Pdf;