import React, {useEffect, useState} from 'react';
import Setting from './modal/setting';
import {useAppDispatch} from "../../hooks";
import {setScrollContent} from "../../store/slices/scrollSlice";
import {setSkipStatus} from "../../store/slices/observerCheck";

const FixButton = () => {
    const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [typeSetting, setTypeSetting] = useState('setting');

  const handleData = () => {
      setOpen(false);
  };

  useEffect(() => {
      dispatch(setScrollContent(scrollY));
  }, [scrollY]);

  return (
      <>
          {open ?
              <Setting handleData={handleData} typeSetting={typeSetting}/> :
              <div className={'share-mobile w-full flex flex-row fixed bottom-4 right-4 px-10 py-5 z-[100]'}>
                  {/*{*/}
                  {/*    window.matchMedia('(max-width: 768px)').matches === true ?*/}
                  {/*        <button onClick={() => {*/}
                  {/*            setOpen(prevState => !prevState);*/}
                  {/*            setTypeSetting('setting');*/}
                  {/*            dispatch(setIsSavedSettings(false));*/}
                  {/*        }*/}
                  {/*        }*/}
                  {/*                className={'bg-[#333333] font-semibold text-white fixed bottom-4 right-4 px-5 py-5 rounded-3xl font-bold shadow-2xl flex flex-row items-center justify-between rounded-[14px] mr-[80px]'}>*/}
                  {/*            <img className={'mr-4'} src="/img/candle-2.svg" alt="candle-2"/>*/}
                  {/*            Настройка*/}
                  {/*        </button> : null*/}
                  {/*}*/}
                  <button onClick={() => {
                      setScrollY(window.scrollY);
                      document.body.style.top = `-${window.scrollY}px`;
                      setOpen(prevState => !prevState);
                      setTypeSetting('share');
                      if (window.matchMedia('(max-width: 768px)').matches === true) {
                          document.body.classList.toggle('modal');
                      }
                      dispatch(setSkipStatus(true));
                  }}
                          className="share-btn bg-[#333333] text-white fixed bottom-4 right-4 px-5 py-5 rounded-3xl font-bold shadow-2xl">
                      <img className={'share-icon'} src="/img/share.svg" alt="share-icon"/>
                  </button>
              </div>
          }
      </>
  );
};

export default FixButton;