import { createSlice } from '@reduxjs/toolkit';
import {RootState} from "../store";

interface DataState {
    scroll: any;
}
const initialState: DataState = {
    scroll: 0
}
const scrollSlice = createSlice({
    name: 'scrollY',
    initialState,
    reducers: {
        setScrollContent(state, action) {
            state.scroll = action.payload;
        },
    },
});
export const { setScrollContent } = scrollSlice.actions;
export default scrollSlice.reducer;

export const apiData = (state: RootState) => state.scrollSlice;

