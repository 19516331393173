import React from 'react';
import SectionSlider from './section-slider';
import SectionAbout from './section-about';
import SectionFeatures from './section-features';
import SectionObject from './section-object';
import SectionEstate from './section-estate';
import SectionInfrostrukture from './section-infrostrukture';
import SectionVariants from './section-variants';
import SectionBanks from './section-banks';

const Main = ({data, builder, house}: any) => {
    const hc = data.housing_complex || data.apartment.housing_complex
    const apartament = data.apartment
    const agent = data.agent

    return (
        <main id="pdfContent" className={'max-w-[1280px] w-full mx-auto py-[30px] max-md:pt-[10px] flex flex-col gap-[80px] max-md:gap-[40px]'}>
            <SectionSlider name={hc.name} images={hc.images} hc={hc}/>
            <SectionAbout data={hc}/>
            {hc.advantages ? <SectionFeatures data={hc.advantages}/> : null}
            {builder !== null ? <SectionObject data={hc}/> : null}
            {house !== null ? <SectionEstate data={apartament}/> : ''}
            <SectionInfrostrukture data={hc} estate={hc.estate_type}/>
            {builder !== null && hc.decorations.length > 0 &&
                <SectionVariants data={hc.decorations} title={'Варианты отделки'}/>}
            {builder !== null && hc.construction_phases.length > 0 ?
                <SectionVariants data={hc.construction_phases} title={'Ход строительства'}/> : ''}
            {hc.banking_accreditations.length > 0 && <SectionBanks/>}
        </main>
    );
};

export default Main;