import React, {useEffect, useRef, useState} from 'react';

const SectionAbout = ({data}: any) => {
    const [maxH, setMaxH] = useState<string>('152px');
    const [slovo, setSlovo] = useState<string>('Развернуть');
    const [hideText,setHideText] = useState<boolean>(false);
    const clickDown = () => {
        maxH === '152px' ? setMaxH('none') : setMaxH('152px');
        slovo === 'Развернуть' ? setSlovo('Свернуть') : setSlovo('Развернуть')
    }
    const [checkHeight, setCheckHeight] = useState(false)

    const refHeight = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if(refHeight.current != undefined){
            if(refHeight.current.offsetHeight >= 152){
                setCheckHeight(true)
            }
        }
    }, [refHeight]);

    return (
        <section className={'flex flex-col'}>
            <h2 className={'mb-[30px] leading-[44px] font-[600] text-[36px] max-md:text-[22px] max-md:mb-[15px] text-[#151515]'}>О {data.name}</h2>
            {
                data.description ?
                    <div ref={refHeight} style={{maxHeight: maxH}} dangerouslySetInnerHTML={{__html: data.description }}
                         className={hideText ? 'text-desc fade-in flex flex-col gap-[30px] leading-[26px] font-[400] text-[18px] overflow-hidden' :
                             'text-desc flex flex-col gap-[30px] leading-[26px] font-[400] text-[18px] overflow-hidden'
                         }>
                    </div> : null
            }
            {
                checkHeight ?
                    <div className={'flex items-center gap-[8px] mt-3'}>
                        <div className={'slovo-container flex items-center text-[18px] font-[600] font-semibold max-md:text-black cursor-pointer'}
                             onClick={(event) => {
                                 event.stopPropagation()
                                 clickDown()
                                 setHideText(!hideText)
                             }}>
                            <span className={'slovo mr-[4px] text-[#151515] max-md:underline max-md:underline-offset-4 max-md:underline'}>{slovo}</span>
                            <svg className={hideText ? 'spoiler-span rotate-180 cursor-pointer max-md:hidden' : 'spoiler-span rotate-0 cursor-pointer max-md:hidden'}
                                width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M6.72458 8.46666L6.72707 8.47003L6.73584 8.48188L6.77156 8.52994C6.80323 8.57244 6.85031 8.63535 6.91104 8.71574C7.03252 8.87658 7.20841 9.10715 7.42458 9.38415C7.85768 9.93911 8.44907 10.676 9.0866 11.4103C9.72802 12.149 10.3985 12.8646 10.9913 13.3883C11.2888 13.6512 11.5455 13.8467 11.7528 13.9717C11.8881 14.0532 11.9676 14.0834 12 14.0944C12.0323 14.0834 12.1118 14.0532 12.2471 13.9717C12.4544 13.8467 12.7111 13.6512 13.0086 13.3884C13.6014 12.8646 14.2719 12.149 14.9133 11.4103C15.5508 10.676 16.1422 9.93909 16.5752 9.38413C16.7914 9.10712 16.9673 8.87655 17.0888 8.71571C17.1495 8.63532 17.1966 8.57241 17.2283 8.52991L17.264 8.48184L17.2748 8.46723C17.5695 8.067 18.1334 7.98089 18.5336 8.27565C18.9338 8.57041 19.0193 9.13381 18.7246 9.53403L17.9999 9.00033C18.7246 9.53403 18.7247 9.53393 18.7246 9.53403L18.7207 9.53936L18.7104 9.55324L18.6715 9.60552C18.6377 9.65091 18.5883 9.71691 18.5251 9.80057C18.3988 9.96786 18.2171 10.206 17.9943 10.4915C17.5494 11.0615 16.9373 11.8246 16.2724 12.5903C15.6115 13.3516 14.8808 14.136 14.2004 14.7372C13.8612 15.0369 13.5133 15.3101 13.1764 15.5133C12.8641 15.7015 12.4482 15.9003 12 15.9003C11.5517 15.9003 11.1358 15.7015 10.8235 15.5133C10.4866 15.3101 10.1387 15.0369 9.7995 14.7372C9.11908 14.136 8.38839 13.3516 7.72743 12.5904C7.0626 11.8246 6.45043 11.0616 6.00557 10.4915C5.78276 10.206 5.60107 9.96791 5.47471 9.80063C5.41152 9.71696 5.36212 9.65097 5.32829 9.60558L5.28944 9.5533L5.27917 9.53942L5.27562 9.53461C5.27555 9.53451 5.27524 9.5341 5.94437 9.04129L5.27562 9.53461C4.98086 9.13439 5.06597 8.57047 5.46619 8.27571C5.8664 7.98096 6.42981 8.06648 6.72458 8.46666Z"
                                      fill={"#151515"}/>
                            </svg>
                        </div>
                    </div> : null
            }
        </section>
    );
};

export default SectionAbout;