import React from 'react';

const SectionBanks = () => {
  return (
    <section>
      <h2 className={'mb-[30px] leading-[44px] font-[600] text-[36px] max-md:text-[20px] max-md:mb-[15px]'}>Банковская аккредитация</h2>
      <div className={'grid grid-cols-3 max-md:grid-cols-2 gap-[16px]'}>
        <div
          className={'flex flex-row gap-[16px] py-[16px] px-[30px] items-center border-[1px] rounded-[14px] border-[#E6E6E6]'}>
          <img src="icons/banks/logo1.svg" className={'max-md:w-[24px]'} alt="" />
          <div className={'w-full'}>
            <p className={'text-[14px] max-md:text-[10px] font-[400] leading-[18px]'}>Сбербанк</p>
            <span
              className={'text-[18px] max-md:text-[14px] font-[600] leading-[26px] flex flex-col'}>5 программ
              <span className={'text-black text-[16px]'}>от 4%</span>
            </span>
          </div>
        </div>
        <div
          className={'flex flex-row gap-[16px] py-[16px] px-[30px] items-center border-[1px] rounded-[14px] border-[#E6E6E6]'}>
          <img src="icons/banks/logo3.svg" className={'max-md:w-[24px]'} alt="" />
          <div className={'w-full'}>
            <p className={'text-[14px] max-md:text-[10px] font-[400] leading-[18px]'}>Открытие</p>
            <span
                className={'text-[18px] max-md:text-[14px] font-[600] leading-[26px]'}>4 программы
              <span className={'text-black text-[16px] block'}>от 4%</span>
            </span>
          </div>
        </div>
        <div className={'flex flex-row gap-[16px] py-[16px] px-[30px] items-center border-[1px] rounded-[14px] border-[#E6E6E6]'}>
          <img src="icons/banks/logo9.svg" className={'max-md:w-[24px]'} alt="" />
          <div className={'w-full'}>
            <p className={'text-[14px] max-md:text-[10px] font-[400] leading-[18px]'}>ВТБ</p>
            <span
                className={'text-[18px] max-md:text-[14px] font-[600] leading-[26px]'}>4 программы
              <span className={'text-black text-[16px] block'}>от 4%</span>
            </span>
          </div>
        </div>
        <div className={'flex flex-row gap-[16px] py-[16px] px-[30px] items-center border-[1px] rounded-[14px] border-[#E6E6E6]'}>
          <img src="icons/banks/logo6.svg" className={'max-md:w-[24px]'} alt="" />
          <div className={'w-full'}>
            <p className={'text-[14px] max-md:text-[10px] font-[400] leading-[18px]'}>РОСБАНК</p>
            <span
                className={'text-[18px] max-md:text-[14px] font-[600] leading-[26px]'}>4 программы
              <span className={'text-black text-[16px] block'}>от 4%</span>
            </span>
          </div>
        </div>
        <div className={'flex flex-row gap-[16px] py-[16px] px-[30px] items-center border-[1px] rounded-[14px] border-[#E6E6E6]'}>
          <img src="icons/banks/logo2.svg" className={'max-md:w-[24px]'} alt="" />
          <div className={'w-full'}>
            <p className={'text-[14px] max-md:text-[10px] font-[400] leading-[18px]'}>Ак Барс Банк</p>
            <span className={'text-[18px] max-md:text-[14px] font-[600] leading-[26px]'}>4 программы
                <span className={'text-black text-[16px] block'}>от 4%</span>
            </span>
          </div>
        </div>
        <div className={'flex flex-row gap-[16px] py-[16px] px-[30px] items-center border-[1px] rounded-[14px] border-[#E6E6E6]'}>
          <img src="icons/banks/logo8.svg" className={'max-md:w-[24px]'} alt="" />
          <div className={'w-full'}>
            <p className={'text-[14px] max-md:text-[10px] font-[400] leading-[18px]'}>ЮниКредит Банк</p>
            <span
                className={'text-[18px] max-md:text-[14px] font-[600] leading-[26px]'}>4 программы
                <span className={'text-black text-[16px] block'}>от 4%</span>
            </span>
          </div>
        </div>
        <div className={'flex flex-row gap-[16px] py-[16px] px-[30px] items-center border-[1px] rounded-[14px] border-[#E6E6E6]'}>
          <img src="icons/banks/logo5.svg" className={'max-md:w-[24px]'} alt="" />
          <div className={'w-full'}>
            <p className={'text-[14px] max-md:text-[10px] font-[400] leading-[18px]'}>Промсвязь Банк</p>
            <span
                className={'text-[18px] max-md:text-[14px] font-[600] leading-[26px]'}>4 программы
              <span className={'text-black text-[16px] block'}>от 4%</span>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionBanks;