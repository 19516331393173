import { createSlice } from '@reduxjs/toolkit';
import {RootState} from "../store";

interface DataState {
    skipStatus: boolean;
}
const initialState: DataState = {
    skipStatus: false
}
const skipStatusSlice = createSlice({
    name: 'skipStatus',
    initialState,
    reducers: {
        setSkipStatus(state, action) {
            state.skipStatus = action.payload;
        },
    },
});
export const { setSkipStatus } = skipStatusSlice.actions;
export default skipStatusSlice.reducer;

export const skip = (state: RootState) => state.skipStatusSlice;

