import { createSlice } from '@reduxjs/toolkit';
import {RootState} from "../store";

interface DataState {
    dataPDFSettings: any;
}
const initialState: DataState = {
    dataPDFSettings: false
}
const dataPDFSettings = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setPDFSettings(state, action) {
            state.dataPDFSettings = action.payload;
        },
    },
});
export const { setPDFSettings } = dataPDFSettings.actions;
export default dataPDFSettings.reducer;

export const apiData = (state: RootState) => state.dataPDFSetting;

