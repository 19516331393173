import React, {useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination, FreeMode, Thumbs} from 'swiper/modules';
import 'swiper/css/thumbs';
import 'swiper/css/free-mode';
import {formatPrice, getQuarter} from "../helpers";

const SectionEstate = ({data}: any) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const estate = data.estate ? data.estate : null;
    const year = estate.delivery_date ? new Date(estate.delivery_date).getFullYear() : null;
    const flat_plan = data.flat_plan_image ? data.flat_plan_image?.links.large : null;

    const checkMobileMeida = window.matchMedia('(max-width: 768px)').matches === true;

    return (
        <section>
            {
                data.total_area ?
                    <h2 className={'mb-[24px] leading-[40px] font-semibold text-[36px] max-md:text-[20px] max-md:mb-[15px]'}>Квартира {data.number != null && data.number !== '-'
                        ? `№${data.number}` : null}</h2>
                    : null
            }
            <div className={'grid grid-cols-2 max-md:grid-cols-1 gap-[24px]'}>
                <div className={'w-full flex flex-col gap-[24px]'}>
                    <Swiper
                        height={350}
                        navigation={true}
                        spaceBetween={50}
                        slidesPerView={1}
                        pagination={{
                            type: 'fraction',
                        }}
                        // @ts-ignore
                        thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                        modules={[Pagination, Navigation, FreeMode, Thumbs]}
                    >
                        {flat_plan && <SwiperSlide className={'max-h-[492px]'}>
                            <div className={'w-full flex items-center justify-center h-[inherit]'}>
                                <img className={'h-[inherit]'} src={flat_plan} alt="flat plan"/>
                            </div>
                        </SwiperSlide>}
                        {estate?.images?.map((image: any) => (
                            <SwiperSlide className={'max-h-[492px]'} key={image.id}>
                                <div className={'w-full flex items-center justify-center h-[inherit]'}>
                                    <img className={'h-[inherit]'} src={image.links.large} alt={image.id}/>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <Swiper
                        // @ts-ignore
                        onSwiper={setThumbsSwiper}
                        spaceBetween={10}
                        slidesPerView={3}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="thumbailsSlider"
                    >
                        {flat_plan && <SwiperSlide className={'max-h-[106px]'}>
                            <img src={flat_plan}/>
                        </SwiperSlide>}
                        {estate?.images?.map((image: any) => (
                            <SwiperSlide className={'max-h-[106px]'} key={image.id}>
                                <img src={image.links.large}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className={'max-w-[560px] max-md:max-w-[100%] w-full flex flex-col gap-[16px]'}>
                    <div className={'price p-[30px] flex flex-col gap-[8px] bg-[#333333] rounded-[24px]'}>
                        <span className={'text-[#b3b3b3] text-[18px] font-[600] leading-[18px]'}>Цена</span>
                        <p className={'text-[#ffffff] text-[28px] font-[600] leading-[36px]'}>{data.price ? formatPrice(data.price) : null}</p>
                        <span
                            className={'text-[#b3b3b3] text-[20px] font-[600] leading-[26px]'}>{data.price_m2 ? formatPrice(data.price_m2) : null}
                            &nbsp;за м<sup>2</sup>
                        </span>
                    </div>
                    <div className={'ops p-[30px] flex flex-col gap-[8px] bg-[#fafafa] border-[1px] border-[#e6e6e6] rounded-[24px]'}>
                        {
                            estate.delivery_date ?
                                <div className={'grid grid-cols-2 gap-[24px]'}>
                                    <span className={'text-[#151515] text-[18px] font-[600] leading-[26px]'}>Срок выдачи ключей</span>
                                    <span className={'text-[#4d4d4d] text-[18px] font-[400] leading-[26px]'}>{getQuarter(estate.delivery_date)} квартал {year} года</span>
                                </div> : null
                        }
                        {
                            data.total_area ?
                                <div className={'grid grid-cols-2 gap-[24px]'}>
                                    <span className={'text-[#151515] text-[18px] font-[600] leading-[26px]'}>Площадь общая</span>
                                    <span className={'text-[#4d4d4d] text-[18px] font-[400] leading-[26px]'}>
                                        {data.total_area} м<sup>2</sup>
                                    </span>
                                </div>
                                : null
                        }
                        {data.living_area !== null && <div className={'grid grid-cols-2 gap-[24px]'}>
                            <span className={'text-[#151515] text-[18px] font-[600] leading-[26px]'}>
                               Площадь жилая
                            </span>
                            <span className={'text-[#4d4d4d] text-[18px] font-[400] leading-[26px]'}>
                                {data.living_area} м<sup>2</sup>
                            </span>
                        </div>}
                        <div className={'grid grid-cols-2 gap-[24px]'}>
                            <span className={'text-[#151515] text-[18px] font-[600] leading-[26px]'}>
                               Этаж
                            </span>
                            <span className={'text-[#4d4d4d] text-[18px] font-[400] leading-[26px]'}>
                                {data?.max_floor > 0 ? `${data?.floor} из ${data?.max_floor}` : data.floor}
                            </span>
                        </div>
                        {/*{*/}
                        {/*    data.housing_complex.floor_max ?*/}
                        {/*        <div className={'grid grid-cols-2 gap-[24px]'}>*/}
                        {/*            <span className={'text-[#151515] text-[18px] font-[600] leading-[26px]'}>*/}
                        {/*                Этажность*/}
                        {/*            </span>*/}
                        {/*            {*/}
                        {/*                data.housing_complex.floor_min ?*/}
                        {/*                    <span className={'text-[#4d4d4d] text-[18px] font-[400] leading-[26px]'}>*/}
                        {/*                        {data.housing_complex.floor_min } – {data.housing_complex.floor_max}*/}
                        {/*                    </span> :*/}
                        {/*                    <span className={'text-[#4d4d4d] text-[18px] font-[400] leading-[26px]'}>*/}
                        {/*                        {data.housing_complex.floor_max}*/}
                        {/*                    </span>*/}
                        {/*            }*/}
                        {/*        </div> : null*/}
                        {/*}*/}
                        {data.entrance_number !== 0 && <div className={'grid grid-cols-2 gap-[24px]'}>
                            <span className={'text-[#151515] text-[18px] font-[600] leading-[26px]'}>
                                Секция
                            </span>
                            <span className={'text-[#4d4d4d] text-[18px] font-[400] leading-[26px]'}>
                                {data.entrance_number.replace('Секция', '')}
                            </span>
                        </div>}
                        {
                            checkMobileMeida ? <hr/> : null
                        }
                        {
                            checkMobileMeida ? <div className={'dop-ops flex flex-col gap-[8px]'}>
                                {
                                    data.ceiling_height ?
                                        <div className={'grid grid-cols-2 gap-[24px]'}>
                                    <span className={'text-[#151515] text-[18px] font-[600] leading-[26px]'}>
                                        Потолки
                                    </span>
                                            <span className={'text-[#4d4d4d] text-[18px] font-[400] leading-[26px]'}>
                                       {data.ceiling_height ? data.ceiling_height : '-'}
                                    </span>
                                        </div> : null
                                }
                                {
                                    data.balcony_area !== null ? <div className={'grid grid-cols-2 gap-[24px]'}>
                                    <span className={'text-[#151515] text-[18px] font-[600] leading-[26px]'}>
                                        Лоджия
                                    </span>
                                        <span className={'text-[#4d4d4d] text-[18px] font-[400] leading-[26px]'}>
                                        {data.balcony_area !== null ? `${data.balcony_area} м<sup>2</sup> (утеплена)` : '-'}
                                    </span>
                                    </div> : null
                                }
                                {estate?.elevator_types.length > 0 &&
                                    <div className={'grid grid-cols-2 gap-[24px]'}>
                                    <span className={'text-[#151515] text-[18px] font-[600] leading-[26px]'}>
                                        Лифт
                                    </span>
                                        <span className={'text-[#4d4d4d] text-[18px] font-[400] leading-[26px]'}>
                                       {estate.elevator_types[0].name}
                                    </span>
                                    </div>}
                                {estate?.parking_types.length > 0 &&
                                    <div className={'grid grid-cols-2 gap-[24px]'}>
                                    <span className={'text-[#151515] text-[18px] font-[600] leading-[26px]'}>
                                        Паркинг
                                    </span>
                                        <span className={'text-[#4d4d4d] text-[18px] font-[400] leading-[26px]'}>
                                        {estate.parking_types[0].name.replace('+','\nи\n')}
                                    </span>
                                    </div>}
                            </div>
                                : null
                        }
                    </div>
                    {
                        checkMobileMeida ? null :
                            <div className={'dop-ops flex flex-col gap-[8px] p-[30px] bg-[#ffffff] border-[1px] border-[#e6e6e6] rounded-[24px]'}>
                                {
                                    data.ceiling_height ?
                                        <div className={'grid grid-cols-2 gap-[24px]'}>
                                    <span className={'text-[#151515] text-[18px] font-[600] leading-[26px]'}>
                                        Потолки
                                    </span>
                                            <span className={'text-[#4d4d4d] text-[18px] font-[400] leading-[26px]'}>
                                       {data.ceiling_height ? data.ceiling_height : '-'}
                                    </span>
                                        </div> : null
                                }
                                {
                                    data.balcony_area !== null ? <div className={'grid grid-cols-2 gap-[24px]'}>
                                    <span className={'text-[#151515] text-[18px] font-[600] leading-[26px]'}>
                                        Лоджия
                                    </span>
                                        <span className={'text-[#4d4d4d] text-[18px] font-[400] leading-[26px]'}>
                                        {data.balcony_area !== null ? `${data.balcony_area} м<sup>2</sup> (утеплена)` : '-'}
                                    </span>
                                    </div> : null
                                }
                                {estate?.elevator_types.length > 0 &&
                                    <div className={'grid grid-cols-2 gap-[24px]'}>
                                    <span className={'text-[#151515] text-[18px] font-[600] leading-[26px]'}>
                                        Лифт
                                    </span>
                                        <span className={'text-[#4d4d4d] text-[18px] font-[400] leading-[26px]'}>
                                       {estate.elevator_types[0].name}
                                    </span>
                                    </div>}
                                {estate?.parking_types.length > 0 &&
                                    <div className={'grid grid-cols-2 gap-[24px]'}>
                                    <span className={'text-[#151515] text-[18px] font-[600] leading-[26px]'}>
                                        Паркинг
                                    </span>
                                        <span className={'text-[#4d4d4d] text-[18px] font-[400] leading-[26px]'}>
                                        {estate.parking_types[0].name.replace('+','\nи\n')}
                                    </span>
                                    </div>}
                            </div>
                    }
                </div>
            </div>
        </section>
    );
};

export default SectionEstate;