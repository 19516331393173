import { createSlice } from '@reduxjs/toolkit';
import {RootState} from "../store";

interface DataState {
    modalSettings: any;
    isSaved: boolean;
    modalSettingsSaved: any;
}
const initialState: DataState = {
    modalSettings: {
        agregatorData: {
            status: false
        },
        agentData: {
            status: false
        },
        complexName: {
            status: false
        },
        hcDesc: {
            status: false
        },
        preferences: {
            status: false
        },
        aboutHome: {
            status: false
        },
        documents: {
            status: false
        },
    },
    isSaved: false,
    modalSettingsSaved: null
}
const modalSettings = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setModalSettings(state, action) {
            const { name, status } = action.payload;
            if (name in state.modalSettings) {
                state.modalSettings[name].status = status;
            } else {
                state.modalSettings = { ...state.modalSettings, ...action.payload };
            }
        },
        setIsSavedSettings(state, action ) {
            state.isSaved = action.payload;

            if(state.isSaved){
                state.modalSettingsSaved = state.modalSettings;
            }

            if(!state.isSaved && state.modalSettingsSaved !== null){
                state.modalSettings = state.modalSettingsSaved;
            }

            if(!state.isSaved && state.modalSettingsSaved === null){
                for (let key in state.modalSettings) {
                    state.modalSettings[key].status = false;
                }
            }
        }
    },
});
export const { setModalSettings, setIsSavedSettings } = modalSettings.actions;
export default modalSettings.reducer;

export const modalSetting = (state: RootState) => state.modalSettings;

